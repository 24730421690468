/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import {
  Col,
  Divider, Form, Input, Row, Select, Space
} from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getValidationRules } from '../../utils/validations';
import Button from '../button';
import TableCropProfile from './form-table-crop-profile';
import TableSuperPerfil from './form-table-crop-super-profile';

const { Item } = Form;
const FormCropProfile = ({
  initialValues, data, onDelete, onCreate, onEdit, onSave, showId, establishments,
  selectDefault
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const rol = localStorage.getItem('rol');
  const { cropprofile } = useSelector((state) => state.cropprofileReducer);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave(values);
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      establecimientos: selectDefault,
    });
  }, [initialValues]);
  return (
    <div>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={8}>
          <Col xs={24} sm={24} md={8}>
            <Space size="middle" direction="vertical">
              <Form.Item name="nombre" label="Nombre" rules={getValidationRules('el nombre')}>
                <Input />
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Space size="middle" direction="vertical">
              <Form.Item name="color" label="Color" rules={getValidationRules('el color')}>
                <Input />
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Space size="middle" direction="vertical">
              <Form.Item required={rol !== 'superadmin'} name="establecimientos" label="Establecimientos">
                <Select
                  mode="tags"
                  tokenSeparators={[',']}
                  options={establishments}
                  style={{ width: 300 }}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Divider />
        <TableCropProfile
          data={data}
          onDelete={onDelete}
          onCreate={(values) => onCreate(values)}
          onEdit={(values) => onEdit(values)}
          showId={showId}
        />
        <Divider />
        <div className="container-page__center-buttons">
          <Item>
            <Button
              text="Guardar cambios"
              onClick={handleSave}
            />
          </Item>
          <Button
            text="Cancelar"
            onClick={handleGoBack}
          />
        </div>
      </Form>
      {cropprofile?.superPerfil === 'Si'
     && (
       <>
         <Divider />
         <p className="container-page__subtitle">Alertas y recomendaciones</p>
         <Divider />
         <TableSuperPerfil
           data={cropprofile.recomendaciones}
         />
       </>
     )}
    </div>
  );
};
FormCropProfile.propTypes = {
  initialValues: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showId: PropTypes.bool,
  establishments: PropTypes.arrayOf(PropTypes.shape()),
  selectDefault: PropTypes.arrayOf(PropTypes.shape()),

};
FormCropProfile.defaultProps = {
  initialValues: {},
  data: [],
  showId: false,
  establishments: [],
  selectDefault: [],
};
export default FormCropProfile;
