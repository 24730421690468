import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Input from '../../../components/input';
import { parseDate } from '../../../utils/parsers/date';

const InfoDetail = ({ campaign }) => {
  const dateStart = campaign.fechaInicio ? parseDate(campaign.fechaInicio) : '-';
  const dateEnd = campaign.fechaFin ? parseDate(campaign.fechaFin) : '-';
  const dateGramos = campaign.gramos ? campaign.gramos : '-';

  return (
    <div>
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} sm={12} md={4}>
          <p>Fecha de inicio</p>
          <Input disabled value={dateStart} />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <p>Fecha de fin</p>
          <Input disabled value={dateEnd} />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <p>Módulo</p>
          <Input disabled value={campaign.modulo} />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <p>Perfil de cultivo</p>
          <Input disabled value={campaign.perfilcultivo} />
        </Col>
        <Col xs={24} sm={12} md={4}>
          <p>Gramos</p>
          <Input disabled value={dateGramos} />
        </Col>
      </Row>
    </div>
  );
};

InfoDetail.propTypes = {
  campaign: PropTypes.shape({
    fechaInicio: PropTypes.string,
    fechaFin: PropTypes.string,
    modulo: PropTypes.string,
    perfilcultivo: PropTypes.string,
    gramos: PropTypes.number,
  }),
};

InfoDetail.defaultProps = {
  campaign: {},
};

export default InfoDetail;
