import { fechaHora } from './date';

export const parseBinnacle = ({
  _id,
  fecha,
  tipo,
  comentarios,
}) => ({
  key: _id,
  fecha: fechaHora(fecha),
  acontecimiento: tipo?.nombre,
  comentarios
});

export const parseBinnacleList = (list) => list?.map((camp) => parseBinnacle(camp));
