/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';

const ModuleArrow = ({
  onClick, icon, size, color, position = 50
}) => {
  const arrowStyles = {
    position: 'absolute',
    top: `${position}%`,
    zIndex: 1,
    fontSize: size,
    color,
    fontWeitght: 'bold',
    background: 'transparent',
    cursor: 'pointer',
    transform: 'translateX(-50%)',
  };
  return (
    <div
      style={{ ...arrowStyles, left: icon === 'left' ? '-30px' : 'auto', right: icon === 'right' ? '-40px' : 'auto' }}
      onClick={onClick}
    >
      {icon === 'left' ? <LeftOutlined /> : <RightOutlined />}
    </div>
  );
};
export default ModuleArrow;
