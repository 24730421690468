/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import { fechaHora } from './date';

const parseHistorical = ({
  estado,
  fecha,
  numeroModulo,
  parametro,
  valor
}) => ({
  key: fecha + numeroModulo,
  estado,
  fecha: fechaHora(fecha),
  nroModulo: numeroModulo,
  parametro,
  valor
});
export const parserHistoricaltList = (list) => list?.map((hist) => parseHistorical(hist));

export const parserModule = ({
  _id, numero, perfilcultivo
}) => ({
  key: _id,
  numero,
  perfilDeCultivo: perfilcultivo?.nombre,
  perfilId: perfilcultivo?._id
});

export const parserModuleList = (list) => list?.map((user) => parserModule(user));

export const parserEstablishment = ({
  _id, nombre, latitud, longitud, umbral1, umbral2,
  historial, modulos, tiempo_actualizacion, cropprofiles
}) => ({
  key: _id,
  nombre,
  latitud,
  longitud,
  umbral1,
  umbral2,
  tiempo_actualizacion,
  historial: parserHistoricaltList(historial),
  modulos: parserModuleList(modulos),
  cropprofiles,
});

export const parserEstablishmentList = (list) => list?.map((est) => parserEstablishment(est));

export const parserSelect = (list) => {
  let select = [];
  if (Array.isArray(list)) {
    select = list?.map((est) => (
      { value: est?._id, label: est?.nombre }));
  }
  return select;
};
