/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../components/title';
import withAuth from '../../hoc';
import { getAllReports, cleanEstablishment } from '../../redux/actions/establishment';
import { getLastWeek, parseDate } from '../../utils/parsers/date';
import ChartReport from './chart';
import Spinner from '../../components/spinner';

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const Reports = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { reports, isLoading } = useSelector((state) => state.establishmentReducer);

  const [dates, setDates] = useState([dayjs(getLastWeek(), dateFormat),
    dayjs(parseDate(new Date()), dateFormat)]);
  const disabledDate = (current) => current && (current > moment().endOf('day'));

  useEffect(() => {
    const startDate = new Date(dates[0].$d);
    const endDate = new Date(dates[1].$d);
    dispatch(getAllReports(id, startDate, endDate));
    return (() => {
      dispatch(cleanEstablishment());
    });
  }, [dates]);

  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Reportes" />
        <RangePicker
          defaultValue={dates}
          disabledDate={disabledDate}
          format="DD/MM/YYYY"
          onChange={(dates) => setDates(dates)}
          placement="bottomRight"
          allowClear={false}
        />
      </div>
      {isLoading ? <Spinner />
        : (
          <div>
            {reports?.modulos.length !== 0
              ? (
                <div className="container-page__modules">
                  {reports?.modulos.map((modulo) => (
                    <>
                      {modulo.perfilcultivo !== undefined ? (
                        <ChartReport
                          key={modulo._id}
                          data={modulo}
                        />
                      ) : <p className="container-page__subtitle">{`El módulo ${modulo.numero} no tienen perfiles de cultivos asociados`}</p>}
                    </>
                  ))}
                </div>
              )
              : (
                <p className="container-page__subtitle">No hay módulos</p>
              )}
          </div>
        )}
    </div>
  );
};

export default withAuth(Reports);
