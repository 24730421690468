export const SET_VERSION = 'SET_VERSION';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// user
export const GET_USER = 'GET_USER';
export const GET_USER_ESTABLISHMENT = 'GET_USER_ESTABLISHMENT';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const CLEAN_USER = 'CLEAN_USER';
export const ENABLED_NOTIFICATION = 'ENABLED_NOTIFICATION';
export const DISABLED_NOTIFICATION = 'DISABLED_NOTIFICATION';

// establecimiento
export const GET_ESTABLISHMENT = 'GET_ESTABLISHMENT';
export const UPDATE_ESTABLISHMENT = 'UPDATE_ESTABLISHMENT';
export const DELETE_ESTABLISHMENT = 'DELETE_ESTABLISHMENT';
export const CREATE_ESTABLISHMENT = 'CREATE_ESTABLISHMENT';
export const GET_ESTABLISHMENT_BY_ID = 'GET_ESTABLISHMENT_BY_ID';
export const CLEAN_ESTABLISHMENT = 'CLEAN_ESTABLISHMENT';

// perfil cultivo
export const GET_CROP_PROFILE = 'GET_CROP_PROFILE';
export const UPDATE_CROP_PROFILE = 'UPDATE_CROP_PROFILE';
export const UPDATE_CROP_PROFILE_ORDER = 'UPDATE_CROP_PROFILE_ORDER';
export const DELETE_CROP_PROFILE = 'DELETE_CROP_PROFILE';
export const CREATE_CROP_PROFILE = 'CREATE_CROP_PROFILE';
export const GET_CROP_PROFILE_BY_ID = 'GET_CROP_PROFILE_BY_ID';
export const CLEAN_CROP_PROFILE = 'CLEAN_CROP_PROFILE';

// perfil cultivo
export const GET_MODULE = 'GET_MODULE';
export const UPDATE_MODULE = 'UPDATE_MODULE';
export const DELETE_MODULE = 'DELETE_MODULE';
export const CREATE_MODULE = 'CREATE_MODULE';
export const GET_MODULE_BY_ID = 'GET_MODULE_BY_ID';

// sensor
export const GET_SENSOR = 'GET_SENSOR';
export const GET_SENSOR_VALUES = 'GET_SENSOR_VALUES';
export const CLEAN_SENSOR = 'CLEAN_SENSOR';
export const UPDATE_SENSOR = 'UPDATE_SENSOR';
export const DELETE_SENSOR = 'DELETE_SENSOR';
export const CREATE_SENSOR = 'CREATE_SENSOR';
export const GET_SENSOR_BY_ID = 'GET_SENSOR_BY_ID';
export const GET_SENSOR_BY_TYPE = 'GET_SENSOR_BY_TYPE';

// unidad
export const GET_UNIT = 'GET_UNIT';

// tipo de sensor
export const GET_SENSOR_TYPES = 'GET_SENSOR_TYPES';

// parametro
export const CREATE_PARAMETER = 'CREATE_PARAMETER';
export const DELETE_PARAMETER = 'DELETE_PARAMETER';
export const UPDATE_PARAMETER = 'UPDATE_PARAMETER';

// home
export const GET_ESTABLISHMENT_PANEL = 'GET_ESTABLISHMENT_PANEL';

// reportes
export const GET_REPORTS_BY_ESTABLISHMENT = 'GET_REPORTS_BY_ESTABLISHMENT';

// token firebase
export const POST_TOKEN_FIREBASE = 'POST_TOKEN_FIREBASE';

// campaign
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_CAMPAIGN_BY_ID = 'GET_CAMPAIGN_BY_ID';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const CLEAN_CAMPAIGN = 'CLEAN_CAMPAIGN';

// binnacles
export const GET_BINNACLE = 'GET_BINNACLE';
export const GET_BINNACLE_TYPES = 'GET_BINNACLE_TYPES';
export const GET_BINNACLE_BY_ID = 'GET_BINNACLE_BY_ID';
export const CREATE_BINNACLE = 'CREATE_BINNACLE';
export const UPDATE_BINNACLE = 'UPDATE_BINNACLE';
export const DELETE_BINNACLE = 'DELETE_BINNACLE';
export const CLEAN_BINNACLE = 'CLEAN_BINNACLE';

// modules
export const CLEAN_MODULE = 'CLEAN_MODULE';
