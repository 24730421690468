/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import { Button } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonComponent = ({
  text, color, ...restProps
}) => {
  const colorClass = classNames('button-container', {
    'button-transparent': color === 'transparent',
    'button-transparent-all': color === 'transparent-all',
    'button-transparent-arrow': color === 'transparent-arrow',
    'button-green': color === 'green',
    'button-red': color === 'red',
    'button-grey': color === 'grey',
    'button-black': color === 'black',
    'button-white': color === 'white',
    'button-secondaryGreen': color === 'secondaryGreen',
    'button-transparent-connection': color === 'transparent-connection'

  });
  return (
    <Button
      {...restProps}
      className={colorClass}
    >
      {text}
    </Button>
  );
};
ButtonComponent.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string
};

ButtonComponent.defaultProps = {
  text: '',
  color: 'green'

};
export default ButtonComponent;
