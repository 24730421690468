/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Modal, message,
  Pagination, Popconfirm, Space
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import Button from '../../components/button';
import Table from '../../components/table';
import Title from '../../components/title';
import withAuth from '../../hoc';
import { columnsCropProfile } from '../../utils/table-parser';
import {
  cleanCropProfile,
  deleteCropProfileById,
  getAllCropProfiles,
} from '../../redux/actions/crop-profile';
import { parserCropProfileList } from '../../utils/parsers/crop-profile';
import Spinner from '../../components/spinner';
import { usePagination } from '../../hooks/usePagination';
import { CROP_PROFILE_NEW_PATH, CROP_PROFILE_PATH, ESTABLISHMENTS_PATH } from '../../routes/path';

const CropProfile = () => {
  const dispatch = useDispatch();
  const { establishments } = useSelector((state) => state.cropprofileReducer);
  const rol = localStorage.getItem('rol');

  const navigate = useNavigate();
  const {
    dataSource, handlePage, page, total, isLoading
  } = usePagination(getAllCropProfiles, 'cropprofile');
  const [showModalCrop, setShowModalCrop] = useState(false);

  const getData = (statusCode) => {
    handlePage(1);
    dispatch(getAllCropProfiles(10, 0));
    if (statusCode === 400) setShowModalCrop(true);
  };
  const closeModalCrop = () => {
    setShowModalCrop(false);
  };
  const redirectDetail = (id) => {
    navigate(`${CROP_PROFILE_PATH}/${id}`);
  };

  const handleCreate = () => {
    navigate(CROP_PROFILE_NEW_PATH);
  };

  const handleDelete = (id) => {
    dispatch(deleteCropProfileById(id, getData));
  };

  const handleActionsColumn = (cropprofile) => (
    <Space size="middle">
      <Button
        type="primary"
        onClick={() => redirectDetail(cropprofile.key)}
        icon={<EditOutlined />}
        text="Editar"
        color="transparent"
      />
      <Popconfirm
        placement="left"
        title={`Desea eliminar a ${cropprofile.nombre}`}
        onConfirm={() => handleDelete(cropprofile.key)}
        okText="Si"
        cancelText="No"
      >
        <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
      </Popconfirm>
    </Space>
  );

  useEffect(() => () => {
    dispatch(cleanCropProfile());
  }, []);

  const columsCopy = (text) => (
    <Space size="middle">
      {text.key}
      <CopyToClipboard
        text={text.key}
        onCopy={() => message.success('Copiado')}
      >
        <CopyOutlined />
      </CopyToClipboard>
    </Space>
  );

  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Perfiles de cultivos" />
        {rol !== 'user' && <Button onClick={handleCreate} text="Agregar perfil de cultivo +" color="transparent" />}
      </div>
      {isLoading
        ? <Spinner />
        : (
          <div className="container-page__table">
            <Table
              columns={columnsCropProfile}
              data={dataSource && parserCropProfileList(dataSource)}
              actionsColumn={handleActionsColumn}
              idCopyFunc={columsCopy}
              idCopy
              actions={rol !== 'user'}
            />
            <div className="container-page__pagination">
              <Pagination
                current={page}
                onChange={handlePage}
                total={total}
              />
            </div>
          </div>
        )}
      <Modal
        title="No se puede eliminar,el perfil de cultivo esta asociado a un módulo"
        open={showModalCrop}
        onOk={closeModalCrop}
        onCancel={closeModalCrop}
        footer={null}
      >
        <div>
          {establishments?.data.map(({ id, nombre }) => (
            <div key={id} className="error-modal">
              <p>{nombre}</p>
              <Link target="_blank" to={`${ESTABLISHMENTS_PATH}/${id}`}>Ir al establecimiento</Link>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
export default withAuth(CropProfile);
