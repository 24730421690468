export const LOGIN_API_PATH = '/auth/login';
export const SET_USER_API_PATH = '/user/auth/profile';

export const USER_API_PATH = '/user';
export const USER_ESTABLISHMENT_API_PATH = '/user/establishment';

export const ESTABLISHMENT_API_PATH = '/establishment';

export const CROP_PROFILE_API_PATH = '/crop-profile';

export const CROP_PROFILE_MODULO_API_PATH = '/crop-profile/modulo';

export const MODULE_API_PATH = '/module';

export const SENSOR_API_PATH = '/sensor';
export const SENSOR_VALUES_API_PATH = '/sensor-value/establishment';
export const SENSOR_BY_TYPES_API_PATH = '/sensor/type';

export const UNIT_API_PATH = '/unit';

export const SENSOR_TYPES_API_PATH = '/sensor-types';

export const SENSOR_PARAMETERS_API_PATH = '/sensor-parameters';

export const HOME_API_PATH = '/establishment/data/panel';

export const TOKEN_FIREBASE_PATH = '/tokens-firebase';

export const ENABLED_NOTIFICATION_PATH = '/user/enabled';

export const DISABLED_NOTIFICATION_PATH = '/user/disabled';

export const CAMPAIGN_PATH = '/campaign';
export const CAMPAIGN_ESTABLISHMENT_PATH = '/campaign/establishment';

export const BINNACLES_PATH = '/bitacora';
export const BINNACLES_TYPE_PATH = '/tipobitacora';
