/* eslint-disable no-undef */
import axios from 'axios';

const { REACT_APP_URL_API } = process.env;
export const api = axios.create({
  baseURL: REACT_APP_URL_API,
});

export const saveTokenAuthentication = ({ accessToken }, rol) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('rol', rol);
};
export const removeTokens = () => {
  localStorage.clear();
};

const logout = () => {
  // Realizar las acciones de logout, por ejemplo:
  removeTokens();
  // Redireccionar a la página de inicio de sesión u otra página apropiada
  window.location.href = '/login';
};
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Realizar la acción de logout aquí
      logout();
    }
    return Promise.reject(error);
  }
);

export const setAuthenticationRefresh = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('refreshToken')}`;
};
export const setAuthentication = (accessToken) => {
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const setChangeAuthentication = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
};
