/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Layout
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { logout, resetStore } from '../../redux/actions/app';
import { LOGIN_PATH, ROOT_PAGE_PATH } from '../../routes/path';
import { deleteTokenFirebase, disabledNotifications, enabledNotifications } from '../../redux/actions/user';
import { getFirebaseToken } from '../../firebase';
import HeaderDesktop from './header/destktop/index';
import HeaderMobile from './header/mobile';

const { Content } = Layout;
const LayoutContainer = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id, notificacion, nombre } = useSelector((state) => state.appReducer.user);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 17in)' });

  const [notificationPermission, setNotificationPermission] = useState(
    window.Notification?.permission
  );

  useEffect(() => {
    if ('Notification' in window
      && window.Notification?.permission !== 'granted') {
      window.Notification?.requestPermission().then((permission) => {
        setNotificationPermission(permission);
      });
    }

    setNotificationPermission(window.Notification?.permission);
  }, []);

  const handleNotifications = () => {
    if (notificacion) {
      dispatch(disabledNotifications(_id));
    } else {
      getFirebaseToken();
      dispatch(enabledNotifications(_id));
    }
  };
  const openModal = () => {
    if (notificationPermission !== 'granted') {
      window.Notification?.requestPermission().then((permission) => {
        if (permission === 'granted') {
          dispatch(disabledNotifications(_id));
        } else if (permission === 'denied') {
          dispatch(disabledNotifications(_id));
        } else {
          dispatch(disabledNotifications(_id));
        }
        setNotificationPermission(permission);
      });
    } else {
      dispatch(disabledNotifications(_id));
    }
  };

  const handleLogout = async () => {
    const token = localStorage.getItem('tokenFirebase');
    await dispatch(deleteTokenFirebase({ token }));
    dispatch(logout());
    dispatch(resetStore());
    navigate(LOGIN_PATH);
  };

  return (
    <Layout className="flex">
      {pathname !== LOGIN_PATH && (
        <>
          {isMobile
            ? (
              <HeaderMobile
                nombre={nombre}
                notificacion={notificacion}
                notificationPermission={notificationPermission}
                onLogout={handleLogout}
                onNotifications={handleNotifications}
                openModal={openModal}
              />
            )
            : (
              <HeaderDesktop
                nombre={nombre}
                notificacion={notificacion}
                notificationPermission={notificationPermission}
                onLogout={handleLogout}
                onNotifications={handleNotifications}
                openModal={openModal}
              />
            )}
        </>
      )}
      <div className="flex">
        <Content style={{}} className="flex">
          <div className="flex">{children}</div>
          {(pathname === ROOT_PAGE_PATH && isMobile) && <div className="footerHome" />}
          {(pathname === ROOT_PAGE_PATH && !isSmallScreen)
           && (
           <div className="footerHome">
             <p>Copyright 2023 CROP POINT</p>
           </div>
           )}
        </Content>
      </div>
    </Layout>

  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContainer;
