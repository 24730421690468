import {
  CLEAN_CROP_PROFILE,
  DELETE_CROP_PROFILE,
  GET_CROP_PROFILE,
  GET_CROP_PROFILE_BY_ID,
} from '../actions/types';
import { handleAction } from '../../utils/handleAction';

const initialState = {
  cropprofile: null,
  cropprofiles: [],
  isLoading: false
};
const cropprofileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CROP_PROFILE:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          cropprofiles: payload.data
        }),
      });
    case GET_CROP_PROFILE_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          cropprofile: payload.data
        }),
      });
    case DELETE_CROP_PROFILE:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          cropprofile: payload.data
        }),
      });
    case CLEAN_CROP_PROFILE:
      return { ...state, cropprofile: {}, cropprofiles: [] };
    default:
      return state;
  }
};
export default cropprofileReducer;
