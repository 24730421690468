/* eslint-disable import/no-cycle */
import { ESTABLISHMENT_API_PATH } from '../../service/path';
import {
  CLEAN_ESTABLISHMENT,
  CREATE_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  GET_ESTABLISHMENT,
  GET_ESTABLISHMENT_BY_ID,
  GET_REPORTS_BY_ESTABLISHMENT,
  UPDATE_ESTABLISHMENT,

} from './types';
import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const getAllEstablishments = () => (dispatch) => dispatch({
  type: GET_ESTABLISHMENT,
  promise: api.get(ESTABLISHMENT_API_PATH),
});

export const getEstablishmentById = (id) => (dispatch) => dispatch({
  type: GET_ESTABLISHMENT_BY_ID,
  promise: api.get(`${ESTABLISHMENT_API_PATH}/${id}`)
});

export const createEstablishment = (data, callback) => (dispatch) => dispatch({
  type: CREATE_ESTABLISHMENT,
  promise: api.post(ESTABLISHMENT_API_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El establecimiento fue creado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteEstablishmentById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_ESTABLISHMENT,
  promise: api.delete(`${ESTABLISHMENT_API_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El establecimiento fue eliminado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateEstablishmentById = (id, data, callback) => (dispatch) => dispatch({
  type: UPDATE_ESTABLISHMENT,
  promise: api.put(`${ESTABLISHMENT_API_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El establecimiento fue editado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const cleanEstablishment = () => (dispatch) => dispatch({
  type: CLEAN_ESTABLISHMENT,
});

export const getAllReports = (id, startDate, endDate) => (dispatch) => dispatch({
  type: GET_REPORTS_BY_ESTABLISHMENT,
  promise: api.get(`${ESTABLISHMENT_API_PATH}/${id}/report?startDate=${startDate}&endDate=${endDate}`),
});
