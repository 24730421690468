/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Search, TextArea, Password } = Input;

const InputComponent = ({ type, ...restProps }) => {
  switch (type) {
    case 'search':
      return <Search {...restProps} />;
    case 'textarea':
      return <TextArea {...restProps} />;
    case 'number':
      return <Input autoComplete="off" min={0} type="number" {...restProps} />;
    case 'password':
      return <Password autoComplete="off" {...restProps} />;
    case 'copy':
      return (
        <Input
          {...restProps}
          disabled
          addonAfter={(
            <span
              onClick={(e) => {
                e.preventDefault();
                const input = document.createElement('input');
                input.value = restProps.value;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
                message.success('copiado');
              }}
            >
              <CopyOutlined />
            </span>
          )}
        />
      );
    default:
      return <Input autoComplete="off" type={type} {...restProps} />;
  }
};
InputComponent.propTypes = {
  type: PropTypes.string,
};
InputComponent.defaultProps = {
  type: ''
};

export default InputComponent;
