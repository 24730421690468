/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const CustomArrow = ({
  onClick, icon, size, color, position = 50
}) => {
  const arrowStyles = {
    position: 'absolute',
    top: `${position}%`,
    zIndex: 1,
    fontSize: size,
    color,
    fontWeight: 'bold',
    background: 'transparent',
    cursor: 'pointer',
    transform: 'translateX(-50%)',
  };
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const arrowPositionStyles = {
    ...arrowStyles,
    left: icon === 'left' ? (isMobile ? '40px' : '-20px') : 'auto',
    right: icon === 'right' ? (isMobile ? '20px' : '-40px') : 'auto',
  };

  return (
    <div
      className="custom-arrow"
      style={arrowPositionStyles}
      onClick={onClick}
    >
      {icon === 'left' ? <LeftOutlined className="arrow-carrousel" /> : <RightOutlined className="arrow-carrousel" />}
    </div>
  );
};

export default CustomArrow;
