/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Modal, Pagination, Popconfirm, Space
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import Table from '../../components/table';
import Title from '../../components/title';
import withAuth from '../../hoc';
import { columnsUser } from '../../utils/table-parser';
import FormUser from './form';
import {
  cleanUser, deleteUserById, getAllUsers, updateUserById, createUser
} from '../../redux/actions/user';
import { parserUserList } from '../../utils/parsers/user';
import { parserSelect } from '../../utils/parsers/establishment';
import { getAllEstablishments } from '../../redux/actions/establishment';
import Spinner from '../../components/spinner';
import { usePagination } from '../../hooks/usePagination';

const initialValues = {
  nombre: '',
  apellido: '',
  email: '',
  rol: '',
  establecimientos: []
};

const User = () => {
  const dispatch = useDispatch();
  const {
    dataSource, handlePage, page, total
  } = usePagination(getAllUsers, 'user');

  const { isLoading } = useSelector((state) => state.userReducer);
  const { establishments } = useSelector((state) => state.establishmentReducer);
  const rol = localStorage.getItem('rol');

  const [form, setForm] = useState();
  const [selectDefault, setSelectDefault] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);

  const getData = () => {
    handlePage(1);
    dispatch(getAllUsers(10, 0));
  };

  const openModalEdit = () => {
    setShowModalEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const handleShowModalEdit = (data) => {
    setForm(data);
    openModalEdit();
    const values = data.establecimientos.map(({ _id }) => _id);
    setSelectDefault(values);
  };

  const openModalNew = () => {
    setShowModalNew(true);
  };

  const closeModalNew = () => {
    setShowModalNew(false);
  };

  const handleShowModalNew = () => {
    openModalNew();
  };

  const handleEdit = ({ key, ...data }) => {
    dispatch(updateUserById(key, data, getData));
    closeModalEdit();
  };

  const handleCreate = (data) => {
    dispatch(createUser(data, getData));
    closeModalNew();
  };

  const handleDelete = (id) => {
    dispatch(deleteUserById(id, getData));
  };

  const handleActionsColumn = (user) => (
    <Space size="middle">
      {
        user.rol !== 'Super admin' && (
        <>
          <Button
            type="primary"
            onClick={() => {
              handleShowModalEdit(user);
            }}
            icon={<EditOutlined />}
            text="Editar"
            color="transparent"
          />
          {(rol === 'admin_establishment' && user.rol === 'Admin')
            ? null
            : (
              <Popconfirm
                placement="left"
                title={`¿Desea eliminar a ${user.nombre}?`}
                onConfirm={() => handleDelete(user.key)}
                okText="Si"
                cancelText="No"
              >
                <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
              </Popconfirm>
            )}
        </>
        )
      }
    </Space>
  );

  useEffect(() => {
    dispatch(getAllEstablishments());
    return () => {
      dispatch(cleanUser());
    };
  }, []);

  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Usuarios" />
        <Button onClick={handleShowModalNew} text="Agregar usuario +" color="transparent" />
      </div>
      {isLoading
        ? <Spinner />
        : (
          <div className="container-page__table">
            <Table
              columns={columnsUser}
              data={dataSource && parserUserList(dataSource)}
              actionsColumn={handleActionsColumn}
              actions={rol !== 'user'}
            />
            <div className="container-page__pagination">
              <Pagination
                current={page}
                onChange={handlePage}
                total={total}
              />
            </div>
          </div>
        )}
      <Modal
        title="Editar usuario"
        open={showModalEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <FormUser
          initialValues={form}
          onSubmit={handleEdit}
          establishments={parserSelect(establishments)}
          selectDefault={selectDefault}
        />
      </Modal>
      <Modal
        title="Crear nuevo usuario"
        open={showModalNew}
        onOk={closeModalNew}
        onCancel={closeModalNew}
        footer={null}
      >
        <FormUser
          initialValues={initialValues}
          onSubmit={handleCreate}
          establishments={parserSelect(establishments)}
          selectDefault={selectDefault}
        />
      </Modal>
    </div>
  );
};
export default withAuth(User);
