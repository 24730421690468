/* eslint-disable import/no-cycle */
import { MODULE_API_PATH } from '../../service/path';
import {
  CREATE_MODULE,
  DELETE_MODULE,
  //   GET_MODULE,
  GET_MODULE_BY_ID,
  UPDATE_MODULE
} from './types';
import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

// export const getAllModules = (perPage, offSet) => (dispatch) => dispatch({
//   type: GET_MODULE,
//   promise: api.get(`${MODULE_API_PATH}?perPage=${perPage}&offSet=${offSet}`),
// });

export const getModuleById = (id) => (dispatch) => dispatch({
  type: GET_MODULE_BY_ID,
  promise: api.get(`${MODULE_API_PATH}/${id}`)
});

export const createModule = (data, callback) => (dispatch) => dispatch({
  type: CREATE_MODULE,
  promise: api.post(MODULE_API_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El modulo fue creado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteModuleById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_MODULE,
  promise: api.delete(`${MODULE_API_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El modulo fue eliminado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateModuleById = (id, data, callback) => (dispatch) => dispatch({
  type: UPDATE_MODULE,
  promise: api.put(`${MODULE_API_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El modulo fue editado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});
