/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import { Table } from 'antd';
import PropTypes, { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import SpinnerInto from '../spinner-inner-components';

const TableComponent = ({
  columns, data, isLoading, actionsColumn,
  idCopyFunc, idCopy = false,
  actions, titleCopy, dataCopy, order, ...restProps
}) => {
  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    const newColumns = [...columns];

    const columnAction = columns?.find((col) => col.key === 'acción');
    const columnId = columns?.find((col) => col.key === 'key');
    const columnOrder = columns?.find((col) => col.key === 'ordenDePerfil');

    // if (editing === true && ordenDePerfilColumn) {
    //   ordenDePerfilColumn.render = (text) => (
    //     <input
    //       type="number"
    //       value={text}
    //     />
    //   );
    // } else if (ordenDePerfilColumn) {
    //   ordenDePerfilColumn.render = (text) => text;
    // }

    if (idCopyFunc && idCopy && !columnId) {
      if (titleCopy !== 'Id') {
        newColumns.unshift({
          title: titleCopy,
          dataIndex: 'sensorId',
          key: 'sensorId',
          render: (text, record) => idCopyFunc(record),
        });
      } else {
        newColumns.unshift({
          title: 'Id',
          dataIndex: 'key',
          key: 'key',
          render: (text, record) => idCopyFunc(record),
        });
      }
    }

    if (!columnOrder && order) {
      newColumns?.push({
        title: 'Orden de perfil',
        dataIndex: 'ordenDePerfil',
        key: 'ordenDePerfil',
        render: (_, record) => actionsColumn(record),
      });
    }

    if (!columnAction && actions) {
      newColumns?.push({
        title: 'Acción',
        dataIndex: 'acción',
        key: 'acción',
        render: (_, record) => actionsColumn(record),
      });
    }
    setColumnsList(newColumns);
  }, [columns, data, dataCopy]);

  const NoDataMessage = () => (
    <div style={{ textAlign: 'center', padding: 20 }}>No hay datos disponibles</div>
  );

  return (
    <Table
      columns={columnsList}
      dataSource={data}
      pagination={false}
      loading={{ spinning: isLoading, indicator: <SpinnerInto /> }}
      locale={{
        emptyText: <NoDataMessage />
      }}
      {...restProps}
    />
  );
};
TableComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: bool,
  actionsColumn: PropTypes.func,
  idCopyFunc: PropTypes.func,
  idCopy: PropTypes.bool,
  actions: PropTypes.bool,
  titleCopy: PropTypes.string,
  dataCopy: PropTypes.string,
};
TableComponent.defaultProps = {
  actionsColumn: () => {},
  isLoading: false,
  idCopyFunc: () => {},
  data: [],
  idCopy: false,
  actions: true,
  titleCopy: '',
  dataCopy: ''
};

export default TableComponent;
