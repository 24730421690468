import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  message, Modal, Popconfirm, Space
} from 'antd';
import {
  CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import Table from '../../table';
import Form from './form';
import Button from '../../button';
import { columnsParameters } from '../../../utils/table-parser';
import {
  getSensorById, getSensorByType
} from '../../../redux/actions/sensor';
import { parserSelect } from '../../../utils/parsers/establishment';
import FormSensor from '../../../pages/sensors/form';
import { cleanEstablishment, getAllEstablishments } from '../../../redux/actions/establishment';
import { parserSensor } from '../../../utils/parsers/sensor';
import { CROP_PROFILE_NEW_PATH } from '../../../routes/path';

const initialValuesDetail = {
  parametro: '',
  maximo: '',
  maxCritico: '',
  minimo: '',
  minCritico: '',
  tiempo: ''
};

const TableCropProfile = ({
  data, onDelete, onEdit, onCreate, showId
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    units, sensorTypes, sensor, sensors, isLoadingSensor
  } = useSelector((state) => state.sensorReducer);

  const { establishments } = useSelector((state) => state.establishmentReducer);

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalInfoSensor, setShowModalInfoSensor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [formDetail, setFormDetail] = useState();
  const [sensorId, setSensorId] = useState();
  const [selectDefault, setSelectDefault] = useState([]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModalEdit = () => {
    setShowModalEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const handleChangeDetail = (values) => {
    setFormDetail(values);
  };

  const handleShowModalEdit = (data) => {
    openModalEdit();
    dispatch(getSensorByType(data?.typeSensores));
    setFormEdit({ ...data, sensor: data?.sensorId, });
  };

  // INFO SENSOR
  const openModalInfoSensor = (id) => {
    dispatch(getSensorById(id));
    setShowModalInfoSensor(true);
  };

  const closeModalInfoSensor = () => {
    dispatch(cleanEstablishment());
    setSelectDefault([]);
    setShowModalInfoSensor(false);
  };

  useEffect(() => {
    if (showModalInfoSensor === true) {
      dispatch(getAllEstablishments());
      setSelectDefault(sensor?.establecimientos);
    }
  }, [sensor?.establecimientos]);
  // Hasta aqui info sensor

  const handleCreate = (values) => {
    onCreate(values);
    closeModal();
  };

  const handleEdit = (values) => {
    onEdit(values);
    closeModalEdit();
  };

  const handleDelete = (id) => {
    onDelete(id);
  };

  useEffect(() => {
    if (formDetail?.typeSensores) {
      dispatch(getSensorByType(formDetail?.typeSensores));
    }
    if (formEdit?.typeSensores) {
      dispatch(getSensorByType(formDetail?.typeSensores));
    }
  }, [formDetail?.typeSensores, formEdit?.typeSensores]);

  useEffect(() => {
    let sensorById;
    if (sensors.data) {
      if (formDetail?.sensor) {
        sensorById = sensors?.data.find(({ _id }) => _id === formDetail?.sensor);
      }
      if (formEdit?.sensor) {
        sensorById = sensors?.data.find(({ _id }) => _id === formEdit?.sensor);
      }
    } else {
      if (formDetail?.sensor) {
        sensorById = sensors?.find(({ _id }) => _id === formDetail?.sensor);
      }
      if (formEdit?.sensor) {
        sensorById = sensors?.find(({ _id }) => _id === formEdit?.sensor);
      }
    }
    setSensorId(sensorById);
  }, [formDetail?.sensor, formEdit?.sensor, sensors]);

  const columnsActions = (detail) => (
    <Space>
      { pathname !== CROP_PROFILE_NEW_PATH
    && (
    <Button
      type="primary"
      icon={<EyeOutlined />}
      text="info sensor"
      color="transparent"
      onClick={() => {
        openModalInfoSensor(detail.sensorId);
      }}
    />
    )}

      <Button
        type="primary"
        onClick={() => {
          handleShowModalEdit(detail);
        }}
        icon={<EditOutlined />}
        text="Editar"
        color="transparent"
      />
      <Popconfirm
        placement="left"
        title="¿Desea eliminar el detalle?"
        onConfirm={() => handleDelete(detail.key)}
        okText="Si"
        cancelText="No"
      >
        <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
      </Popconfirm>
    </Space>
  );
  const columsCopy = (text) => (
    <Space size="middle">
      {text.sensorId}
      <CopyToClipboard
        text={text.sensorId}
        onCopy={() => message.success('Copiado')}
      >
        <CopyOutlined />
      </CopyToClipboard>
    </Space>
  );

  return (
    <>
      <div className="container-page__table">
        <Table
          columns={columnsParameters}
          data={data}
          actionsColumn={columnsActions}
          idCopyFunc={columsCopy}
          idCopy={showId}
          titleCopy={showId ? 'Id sensor' : 'Id'}
        />
      </div>
      <div className="container-page__button-new">
        <Button text="Agregar detalles +" color="transparent" onClick={openModal} />
      </div>
      <Modal
        title="Agregar detalle"
        open={showModal}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        <Form
          initialValues={initialValuesDetail}
          onSubmit={(values) => handleCreate(values)}
          onChange={handleChangeDetail}
          sensorTypes={parserSelect(sensorTypes)}
          sensors={parserSelect(sensors)}
          isLoading={isLoadingSensor}
          sensor={sensorId}
        />
      </Modal>
      <Modal
        title="Editar detalle"
        open={showModalEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <Form
          initialValues={formEdit}
          onSubmit={(values) => handleEdit(values)}
          onChange={handleChangeDetail}
          sensorTypes={parserSelect(sensorTypes)}
          sensors={parserSelect(sensors)}
          isLoading={isLoadingSensor}
          sensor={sensorId}
        />
      </Modal>

      <Modal
        title="Informacion del sensor"
        open={showModalInfoSensor}
        onOk={closeModalInfoSensor}
        onCancel={closeModalInfoSensor}
        footer={null}
      >
        <FormSensor
          initialValues={parserSensor(sensor)}
          units={units}
          types={sensorTypes}
          establishments={parserSelect(establishments)}
          selectDefault={selectDefault}
          disabled
        />
      </Modal>
    </>
  );
};

TableCropProfile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  showId: PropTypes.bool,
};
TableCropProfile.defaultProps = {
  data: [],
  showId: false,

};
export default TableCropProfile;
