export const getValidationRules = (key, select) => {
  if (key === 'email') {
    return [
      { required: true, message: 'Por favor ingresa tu nombre de usuario', },
      { type: 'email', message: 'Por favor ingresa un email válido', },
    ];
  }
  if (key === 'password') {
    return [
      { required: true, message: 'Por favor ingresa tu contraseña', },
    ];
  }
  if (key === 'tipo-sensor') {
    return [
      { required: true, message: 'Por favor ingresa el tipo del sensor', },
    ];
  }
  if (key === 'nombre-persona') {
    return [
      { min: 3, message: 'La longitud del nombre tiene que ser mayor a 3', },
      { pattern: /(^[^\d]*$)/, message: 'El nombre no debe tener números', },
      { required: true, message: 'Por favor ingresa el nombre', },
    ];
  }
  if (key === 'apellido') {
    return [
      { min: 3, message: 'La longitud del apellido tiene que ser mayor a 3', },
      { pattern: /^(^[^\d]*$)/, message: 'El apellido no debe tener números', },
      { required: true, message: 'Por favor ingresa el apellido', },
    ];
  }
  if (key === 'password-user') {
    return [
      { min: 8, max: 16, message: 'La longitud de la contraseña debe ser entre 8 y 16 caracteres', },
      { pattern: /^(?=.*[A-Z])/, message: 'La contraseña debe contener al menos una letra mayúscula', },
      { pattern: /^(?=.*[a-z])/, message: 'La contraseña debe tener al menos una letra minúscula', },
      { pattern: /^(?=.*\d)/, message: 'La contraseña debe tener al menos un número', },
    ];
  }
  if (select === 'select') {
    return [
      { required: true, message: `Por favor selecciona ${key}`, },
    ];
  }
  return [
    { required: true, message: `Por favor ingresa ${key}`, },
  ];
};

export const validateFieldsMaximoMinimo = (values, minimoAdmisible, maximoAdmisible) => {
  const {
    maximo, maximoCritico, minimo, minimoCritico
  } = values;
  const newErrors = {};
  if (parseInt(maximo, 10) < parseInt(minimo, 10)) {
    newErrors.maximo = 'El máximo no puede ser menor que el mínimo';
  }
  if (parseInt(minimo, 10) > parseInt(maximo, 10)) {
    newErrors.minimo = 'El mínimo no puede ser mayor que el máximo';
  }
  if (parseInt(maximoCritico, 10) < parseInt(maximo, 10)) {
    newErrors.maximoCritico = 'El máximo crítico debe ser mayor que el máximo';
  }
  if (parseInt(minimoCritico, 10) > parseInt(minimo, 10)) {
    newErrors.minimoCritico = 'El mínimo crítico  debe ser menor que el mínimo';
  }
  if (parseInt(maximo, 10) > maximoAdmisible) {
    newErrors.maximo = 'El máximo no puede ser mayor que el maximo admisible';
  }
  if (parseInt(maximoCritico, 10) > maximoAdmisible) {
    newErrors.maximoCritico = 'El máximo critico no puede ser mayor que el maximo admisible';
  }
  if (parseInt(minimo, 10) < minimoAdmisible) {
    newErrors.minimo = 'El minimo no puede ser menor que el minimo admisible';
  }
  if (parseInt(minimoCritico, 10) < minimoAdmisible) {
    newErrors.minimoCritico = 'El minimo critico no puede ser menor que el minimo admisible';
  }
  return newErrors;
};

export const getValidationMaximoMinimo = (values) => {
  const {
    maximoAdmisible, minimoAdmisible
  } = values;
  const newErrors = {};
  if (parseInt(maximoAdmisible, 10) < parseInt(minimoAdmisible, 10)) {
    newErrors.maximoAdmisible = 'El máximo no puede ser menor que el mínimo';
  }
  if (parseInt(minimoAdmisible, 10) > parseInt(maximoAdmisible, 10)) {
    newErrors.minimoAdmisible = 'El mínimo no puede ser mayor que el máximo';
  }
  return newErrors;
};

export const getValidationUmbrales = (values) => {
  const {
    umbral1, umbral2
  } = values;
  const newErrors = {};
  if (parseInt(umbral2, 10) < parseInt(umbral1, 10)) {
    newErrors.umbral1 = 'El umbral 2 no puede ser menor que el umbral 1';
  }
  if (parseInt(umbral1, 10) > parseInt(umbral2, 10)) {
    newErrors.umbral2 = 'El umbral 1 no puede ser mayor que el umbral 2';
  }
  return newErrors;
};
