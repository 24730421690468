/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom';
import {
  ESTABLISHMENTS_PATH,
  CROP_PROFILE_PATH,
  ROOT_PAGE_PATH,
  SENSORES_PATH,
  USERS_PATH
} from '../routes/path';

export const menu = [
  {
    key: ROOT_PAGE_PATH,
    label: <Link to={ROOT_PAGE_PATH} className="link">HOME</Link>
  },
  {
    key: ESTABLISHMENTS_PATH,
    label: <Link to={ESTABLISHMENTS_PATH} className="link">ESTABLECIMIENTOS</Link>
  },
  {
    key: CROP_PROFILE_PATH,
    label: <Link to={CROP_PROFILE_PATH} className="link">PERFILES DE CULTIVO</Link>
  },
  {
    key: SENSORES_PATH,
    label: <Link to={SENSORES_PATH} className="link">SENSORES</Link>
  },
  {
    key: USERS_PATH,
    label: <Link to={USERS_PATH} className="link">USUARIOS</Link>
  },
];

export const menuRolUser = [
  {
    key: ROOT_PAGE_PATH,
    label: <Link to={ROOT_PAGE_PATH} className="link">HOME</Link>
  },
  {
    key: ESTABLISHMENTS_PATH,
    label: <Link to={ESTABLISHMENTS_PATH} className="link">ESTABLECIMIENTOS</Link>
  },
  {
    key: CROP_PROFILE_PATH,
    label: <Link to={CROP_PROFILE_PATH} className="link">PERFILES DE CULTIVO</Link>
  },
  {
    key: SENSORES_PATH,
    label: <Link to={SENSORES_PATH} className="link">SENSORES</Link>
  },
];
