import {
  arrayOf, number, shape, string
} from 'prop-types';
import React from 'react';
import CardInfoHeader from '../../pages/home/card-info-header';
import Modulos from '../card-info/modulos';

const CardDesktop = ({
  title, longitud, latitud, modules, wifi, idEstablishment
}) => (
  <div className="card-home-desktop">
    <CardInfoHeader
      title={title}
      longitud={longitud}
      latitud={latitud}
    />
    <div className="card-home-desktop__modulos flex">
      <Modulos
        modules={modules}
        wifi={wifi}
        idEstablishment={idEstablishment}
      />
    </div>

  </div>
);
CardDesktop.propTypes = {
  modules: arrayOf(shape()),
  wifi: string,
  idEstablishment: string,
  title: string.isRequired,
  latitud: number.isRequired,
  longitud: number.isRequired,
};
CardDesktop.defaultProps = {
  modules: [],
  wifi: '',
  idEstablishment: '',
};

export default CardDesktop;
