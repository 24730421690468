/* eslint-disable import/no-cycle */
import {
  DISABLED_NOTIFICATION_PATH,
  ENABLED_NOTIFICATION_PATH,
  TOKEN_FIREBASE_PATH,
  USER_API_PATH,
  USER_ESTABLISHMENT_API_PATH
} from '../../service/path';
import {
  CLEAN_USER,
  CREATE_USER,
  DELETE_USER,
  GET_USER,
  GET_USER_BY_ID,
  GET_USER_ESTABLISHMENT,
  POST_TOKEN_FIREBASE,
  ENABLED_NOTIFICATION,
  DISABLED_NOTIFICATION,
  UPDATE_USER
} from './types';
import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const getAllUsers = (perPage, offSet) => (dispatch) => dispatch({
  type: GET_USER,
  promise: api.get(`${USER_API_PATH}?perPage=${perPage}&offSet=${offSet}`),
});

export const getAllUsersEstablishment = (perPage, offSet, id) => (dispatch) => dispatch({
  type: GET_USER_ESTABLISHMENT,
  promise: api.get(`${USER_ESTABLISHMENT_API_PATH}/${id}?perPage=${perPage}&offSet=${offSet}`),
});

export const getUserById = (id) => (dispatch) => dispatch({
  type: GET_USER_BY_ID,
  promise: api.get(`${USER_API_PATH}/${id}`)
});

export const createUser = (data, callback) => (dispatch) => dispatch({
  type: CREATE_USER,
  promise: api.post(USER_API_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El usuario fue creado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteUserById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_USER,
  promise: api.delete(`${USER_API_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El usuario fue eliminado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateUserById = (id, data, callback) => (dispatch) => dispatch({
  type: UPDATE_USER,
  promise: api.put(`${USER_API_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El usuario fue editado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const enabledNotifications = (id) => (dispatch) => dispatch({
  type: ENABLED_NOTIFICATION,
  promise: api.put(`${ENABLED_NOTIFICATION_PATH}/${id}`),
});

export const disabledNotifications = (id) => (dispatch) => dispatch({
  type: DISABLED_NOTIFICATION,
  promise: api.put(`${DISABLED_NOTIFICATION_PATH}/${id}`),
});

export const cleanUser = () => (dispatch) => dispatch({
  type: CLEAN_USER,
});

export const postTokenFirebase = (token) => (dispatch) => dispatch({
  type: POST_TOKEN_FIREBASE,
  promise: api.post(TOKEN_FIREBASE_PATH, { token }),
});

export const deleteTokenFirebase = (data) => (dispatch) => dispatch({
  type: POST_TOKEN_FIREBASE,
  promise: api.post(`${TOKEN_FIREBASE_PATH}/logout`, data),

});
