/* eslint-disable react/prop-types */
import React from 'react';
import { Pagination, Space, message } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import Table from '../../../components/table';
import { parserSensorList } from '../../../utils/parsers/sensor';
import { columnsSensor } from '../../../utils/table-parser';

const TableSensor = ({
  rol, handleActionsColumn, dataSource, page, handlePage, total
}) => {
  const columsCopy = (text) => (
    <Space size="middle">
      {text.key}
      <CopyToClipboard
        text={text.key}
        onCopy={() => message.success('Copiado')}
      >
        <CopyOutlined />
      </CopyToClipboard>
    </Space>
  );
  return (
    <div className="container-page__table">
      {dataSource
    && (
    <Table
      columns={columnsSensor}
      data={parserSensorList(dataSource)}
      actionsColumn={handleActionsColumn}
      idCopyFunc={columsCopy}
      idCopy
      actions={rol !== 'user'}
    />
    )}
      <div className="container-page__pagination">
        <Pagination
          current={page}
          onChange={handlePage}
          total={total}
        />
      </div>
    </div>
  );
};

export default TableSensor;
