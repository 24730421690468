/* eslint-disable no-undef */
import {
  CopyOutlined, DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import {
  Modal, Popconfirm, Space, message
} from 'antd';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getAllCropProfilesModulo } from '../../../redux/actions/crop-profile';
import {
  createModule, deleteModuleById, getModuleById, updateModuleById
} from '../../../redux/actions/module';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsModules } from '../../../utils/table-parser';
import FormModule from './form';

const initialValues = {
  numero: '',
  perfilcultivo: '',
  superficieTotal: '',
  superficieCultivo: '',
  volumenTotal: '',
  intraccionAire: '',
  extraccionAire: '',
  luminaria: '',
  circulacionAire: false,
  aireAcondicionado: false,
  generadorCO: false,
  frigorias: ''
};

const Module = ({
  getData, id, establishment
}) => {
  const [showModalModuleNew, setShowModalModuleNew] = useState(false);
  const [showModalModuleEdit, setShowModalModuleEdit] = useState(false);
  const { cropprofiles } = useSelector((state) => state.cropprofileReducer);
  const { mod } = useSelector((state) => state.moduleReducer);
  const rol = localStorage.getItem('rol');

  const dispatch = useDispatch();
  const openModalNew = () => {
    setShowModalModuleNew(true);
  };

  const closeModalNew = () => {
    setShowModalModuleNew(false);
  };

  const handleshowModalModuleNew = () => {
    dispatch(getAllCropProfilesModulo(id));
    openModalNew();
  };

  const handleCreateModule = (values) => {
    const modulo = {
      ...values,
      establecimiento: id,
      perfilcultivo: perfilcultivo.values
    };
    dispatch(createModule(modulo, getData));
    closeModalNew();
  };

  const openModalEdit = () => {
    setShowModalModuleEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalModuleEdit(false);
  };
  const handleModalEdit = async (data) => {
    await dispatch(getAllCropProfilesModulo(id));
    await dispatch(getModuleById(data?.key));
    openModalEdit();
  };

  const handleDelete = (key) => {
    dispatch(deleteModuleById(key.key, getData));
  };

  const onEditModulo = ({ key, ...data }) => {
    dispatch(updateModuleById(key, data, getData));
    closeModalEdit();
  };
  const actionsColumn = (mod) => (
    <Space size="middle">
      <Button
        type="primary"
        icon={<EditOutlined />}
        color="transparent"
        text="Editar"
        onClick={() => handleModalEdit(mod)}
      />
      <Popconfirm
        placement="left"
        title="Desea eliminar el modulo?"
        onConfirm={() => handleDelete(mod)}
        okText="Si"
        cancelText="No"
      >
        <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
      </Popconfirm>
    </Space>
  );

  const columsCopy = (text) => (
    <Space size="middle">
      {text.key}
      <CopyToClipboard
        text={text.key}
        onCopy={() => message.success('Copiado')}
      >
        <CopyOutlined />
      </CopyToClipboard>
    </Space>
  );
  return (
    <div className="container-page__box">
      <p className="container-page__subtitle">Módulos</p>
      <div className="container-page__button-new">
        {rol !== 'user' && (
        <Button
          text="Agregar módulo +"
          color="transparent"
          onClick={handleshowModalModuleNew}
        />
        )}
      </div>
      <div className="container-page__table">
        <Table
          columns={columnsModules}
          data={establishment.modulos}
          actionsColumn={actionsColumn}
          actions={rol !== 'user'}
          idCopyFunc={columsCopy}
          idCopy
        />
      </div>
      <Modal
        title="Crear nuevo modulo"
        open={showModalModuleNew}
        onOk={closeModalNew}
        onCancel={closeModalNew}
        footer={null}
      >
        <FormModule
          initialValues={initialValues}
          onSubmit={handleCreateModule}
          cropprofiles={cropprofiles}
          type="add-module"
        />
      </Modal>
      <Modal
        title="Editar modulo"
        open={showModalModuleEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <FormModule
          initialValues={mod}
          onSubmit={onEditModulo}
          cropprofiles={cropprofiles}
          type="add-module"
        />
      </Modal>
    </div>
  );
};
Module.propTypes = {
  getData: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  establishment: (PropTypes.shape()).isRequired,
};
export default Module;
