import { fechaHora } from './date';

export const parseCampaign = ({
  _id,
  fechaInicio,
  fechaFin,
  modulo,
  perfilcultivo,
  gramos
}) => ({
  key: _id,
  fechaDeInicio: fechaHora(fechaInicio),
  fechaDeCierre: fechaFin ? fechaHora(fechaFin) : '-',
  modulo,
  perfilDeCultivo: perfilcultivo,
  gramos: gramos || '-'
});

export const parserCampaignList = (list) => list?.map((camp) => parseCampaign(camp));
