import { api } from '../../service/api';
import { CAMPAIGN_ESTABLISHMENT_PATH, CAMPAIGN_PATH } from '../../service/path';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';
import {
  CLEAN_CAMPAIGN,
  CREATE_CAMPAIGN, DELETE_CAMPAIGN,
  GET_CAMPAIGN, GET_CAMPAIGN_BY_ID, UPDATE_CAMPAIGN
} from './types';

export const getCampaignById = (id) => (dispatch) => dispatch({
  type: GET_CAMPAIGN_BY_ID,
  promise: api.get(`${CAMPAIGN_PATH}/${id}`)
});

export const removeCampaign = () => (dispatch) => dispatch({
  type: CLEAN_CAMPAIGN,
});

export const getAllCampaign = (perPage, offSet, id) => (dispatch) => dispatch({
  type: GET_CAMPAIGN,
  promise: api.get(`${CAMPAIGN_ESTABLISHMENT_PATH}/${id}?perPage=${perPage}&offSet=${offSet}`),
});
export const getAllCampaignsNotPagination = (id) => (dispatch) => dispatch({
  type: GET_CAMPAIGN,
  promise: api.get(`${CAMPAIGN_ESTABLISHMENT_PATH}/${id}`),
});

export const createCampaign = (data, callback) => (dispatch) => dispatch({
  type: CREATE_CAMPAIGN,
  promise: api.post(CAMPAIGN_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('La campaña fue creada correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateCampaignById = (id, data) => (dispatch) => dispatch({
  type: UPDATE_CAMPAIGN,
  promise: api.put(`${CAMPAIGN_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('La campaña fue editada correctamente', dispatch);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteCampaignById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_CAMPAIGN,
  promise: api.put(`${CAMPAIGN_PATH}/deleted/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('La campaña fue eliminada correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});
