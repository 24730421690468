import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SpinnerInto from '../../../components/spinner-inner-components';

const CardInfoHeader = ({
  title, latitud, longitud
}) => {
  const [weather, setWeather] = useState();
  const [description, setDescription] = useState();
  const [wind, setWind] = useState();
  const [humidity, setHumidity] = useState();
  const [icon, setIcon] = useState();

  // #region card-info functions
  const getWeather = async () => {
    if (latitud && longitud) {
      const { REACT_APP_API_KEY_WHEATER } = process.env;
      const data = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${latitud}&lon=${longitud}&appid=${REACT_APP_API_KEY_WHEATER}&lang=es&units=metric`);

      const iconData = `http://openweathermap.org/img/wn/${data.data.weather[0].icon}@2x.png`;

      const des = `${data.data.weather[0].description}`;
      const win = `${data.data.wind.speed}m/s `;
      const hum = `${data.data.main.humidity}% `;
      setWeather(data.data.main.temp);
      setDescription(des);
      setWind(win);
      setHumidity(hum);
      setIcon(iconData);
    }
  };

  useEffect(() => {
    getWeather();
  }, []);

  return (
    <>
      <div className="card-header__title">{title}</div>
      {latitud && longitud ? (
        <div>
          {!weather ? <SpinnerInto />
            : (
              <div className="card-header__container-alineation">
                <div className="card-header__container-weather">
                  <img src={icon} alt="Weather Icon" />
                  <p className="card-header__weather">{`${Math.round(weather)} °C`}</p>
                </div>
                <div className="card-header__container-des">
                  <p className="card-header__descriptions">
                    <strong className="card-header__strong">Estado: </strong>
                    {description}
                  </p>
                  <p className="card-header__descriptions">
                    <strong className="card-header__strong"> Humedad: </strong>
                    {humidity}
                  </p>
                  <p className="card-header__descriptions">
                    <strong className="card-header__strong"> Viento: </strong>
                    {wind}
                  </p>
                </div>
              </div>

            )}
        </div>
      ) : <p className="messege-not-content-white">No se agrego latitud y longitud</p>}
    </>
  );
};

CardInfoHeader.propTypes = {
  title: PropTypes.string.isRequired,
  latitud: PropTypes.number.isRequired,
  longitud: PropTypes.number.isRequired,
//   id: PropTypes.string.isRequired,
};

export default CardInfoHeader;
