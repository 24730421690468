/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ModuleArrow from './module-arrow';

const CarouselCard = ({ children, styles }) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    rows: 2,
    slidesToScroll: 2,
    swipe: false,
    prevArrow: <ModuleArrow icon="left" size={20} position={40} />,
    nextArrow: <ModuleArrow icon="right" size={20} position={40} />,
  };
  return (
    <Slider {...settings} className={styles}>
      {children}
    </Slider>
  );
};

CarouselCard.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.string.isRequired,
};
export default CarouselCard;
