/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import {
  Divider, message
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Title from '../../components/title';
import Spinner from '../../components/spinner';
import FormCropProfile from '../../components/form-crop-profile';
import { parserCropProfileNewList, parserProfileNew } from '../../utils/parsers/crop-profile';
import { createCropProfile } from '../../redux/actions/crop-profile';
import { getAllSensorTypes } from '../../redux/actions/sensor';
import { getAllEstablishments } from '../../redux/actions/establishment';
import { parserSelect } from '../../utils/parsers/establishment';

const CreateCropProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    color: '',
    nombre: '',
  };

  useEffect(() => {
    dispatch(getAllEstablishments());
    dispatch(getAllSensorTypes());
  }, []);

  const {
    sensors, isLoading, sensorTypes
  } = useSelector((state) => state.sensorReducer);

  const { establishments } = useSelector((state) => state.establishmentReducer);

  const [details, setDetails] = useState([]);
  const [initVal, setInitVal] = useState({});

  useEffect(() => {
    setInitVal(initialValues);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCreateCropProfile = (values) => {
    const parametros = details.map((detail) => parserProfileNew(detail));
    const formParser = { ...values, parametros };
    dispatch(createCropProfile(formParser, handleGoBack));
  };

  const createParameterSensor = (values) => {
    const sensorTypeFound = sensorTypes.find((st) => st._id === values.typeSensores);
    const { sensor } = values;
    const sensorObject = sensors?.find(({ _id }) => sensor === _id);
    const detail = { ...values, key: uuidv4(), sensor: sensorObject };
    const parameter = details.find(({ sensor: { _id } }) => _id === sensor);
    detail.typeSensores = sensorTypeFound;
    if (!parameter) {
      if ((details.filter(({ typeSensores }) => typeSensores.nombre === 'Temperatura').length === 0
       || details.filter(({ typeSensores }) => typeSensores.nombre === 'Humedad').length === 0)
        && sensorTypeFound.nombre === 'Virtual') {
        message.error('No se puede agregar un sensor virtual sin un sensor Humedad y Temperatura');
      } else {
        setDetails([...details, detail]);
      }
    } else {
      message.error('No se pueden agregar dos sensores del mismo tipo');
    }
  };

  const editParameterSensor = (values) => {
    const { sensor } = values;
    const sensorObject = sensors?.find(({ _id }) => sensor === _id);
    const updatedDetails = [...details];
    const index = updatedDetails?.findIndex((detail) => detail.id === values.id);
    updatedDetails[index] = { ...updatedDetails[index], ...values, sensor: sensorObject };
    const parameter = details.find(({ sensor: { _id } }) => _id === sensor);
    if (!parameter) {
      setDetails(updatedDetails);
    } else {
      message.error('No se pueden agregar dos sensores del mismo tipo');
    }
  };

  const handleRemoveParameter = useCallback((id) => {
    const hasVirtualSensor = details.some((detail) => detail.typeSensores.nombre === 'Virtual');
    const sensorToDelete = details.find((detail) => detail.key === id);

    if (sensorToDelete.typeSensores.nombre === 'Temperatura' || sensorToDelete.typeSensores.nombre === 'Humedad') {
      if (hasVirtualSensor) {
        message.error('No se puede eliminar un sensor de temperatura o humedad sin eliminar el sensor virtual primero.');
        return;
      }
    }
    const removed = details.filter((detail) => detail.key !== id);
    setDetails(removed);
  }, [details]);

  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Crear perfil de cultivo" />
      </div>
      <Divider />
      {isLoading
        ? <Spinner />
        : (
          <FormCropProfile
            initialValues={initVal}
            data={parserCropProfileNewList(details)}
            onCreate={createParameterSensor}
            onDelete={handleRemoveParameter}
            onEdit={editParameterSensor}
            onSave={handleCreateCropProfile}
            establishments={parserSelect(establishments)}
          />
        )}
    </div>
  );
};

export default CreateCropProfile;
