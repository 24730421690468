/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import { Button } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const CardColor = ({
  value, color, onClick, disabled
}) => {
  const colorClass = classNames('card-color', {
    red: color === 'red',
    green: color === 'green',
    yellow: color === 'yellow',
    grey: color === 'grey',
  });
  return (
    <Button className={colorClass} onClick={() => onClick()} disabled={disabled}>
      <p className="card-color__value">{value}</p>
    </Button>
  );
};
CardColor.propTypes = {
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
};
CardColor.defaultProps = {
  disabled: false,
  onClick: () => {},
  color: ''
};
export default CardColor;
