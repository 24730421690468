import { handle } from 'redux-pack';
import {
  CLEAN_ESTABLISHMENT,
  GET_ESTABLISHMENT,
  GET_ESTABLISHMENT_BY_ID,
  GET_ESTABLISHMENT_PANEL,
  GET_REPORTS_BY_ESTABLISHMENT,
} from '../actions/types';
import { handleAction } from '../../utils/handleAction';
import { parserEstablishment } from '../../utils/parsers/establishment';

const initialState = {
  establishment: {},
  establishments: null,
  isLoading: false
};
const establishmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ESTABLISHMENT:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          establishments: payload.data
        }),
      });
    case GET_REPORTS_BY_ESTABLISHMENT:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          reports: payload.data
        }),
      });
    case GET_ESTABLISHMENT_PANEL:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          establishments: payload.data
        }),
      });
    case GET_ESTABLISHMENT_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          establishment: parserEstablishment(payload.data)
        }),
      });
    case CLEAN_ESTABLISHMENT:
      return {
        ...state, establishment: {}, establishments: null, reports: null
      };
    default:
      return state;
  }
};
export default establishmentReducer;
