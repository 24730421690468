/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Row, Select
} from 'antd';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { getValidationRules } from '../../../../utils/validations';
import Input from '../../../../components/input';

const { Option } = Select;
const { Item } = Form;

const FormUser = ({
  onSubmit, initialValues, establishments
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (values.password === '') delete values.password;
    onSubmit({ key: initialValues.key, ...values });
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      rol: initialValues.rolId,
      establecimientos: [id]

    });
  }, [initialValues]);

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Row gutter={24}>
        <Col xs={24} sm={8} span={24}>
          <Item name="nombre" label="Nombre" rules={getValidationRules('nombre-persona')}>
            <Input />
          </Item>
        </Col>
        <Col xs={24} sm={8} span={24}>
          <Item name="apellido" label="Apellido" rules={getValidationRules('apellido')}>
            <Input />
          </Item>
        </Col>
        <Col xs={24} sm={8} span={24}>
          <Item name="email" label="Email" rules={getValidationRules('email')}>
            <Input />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={8} span={24}>
          <Item autoComplete="off" name="password" label="Contraseña" rules={getValidationRules('password-user')}>
            <Input type="password" />
          </Item>
        </Col>
        <Col xs={24} sm={8} span={24}>
          <Item name="rol" label="Rol" rules={getValidationRules('un rol', 'select')}>
            <Select>
              <Option value="admin_establishment">Admin</Option>
              <Option value="user">Usuario</Option>
            </Select>
          </Item>
        </Col>
      </Row>
      <Item name="establecimientos" label="Establecimientos">
        <Select
          disabled
          mode="tags"
          style={{
            width: '100%',
          }}
          tokenSeparators={[',']}
          options={establishments}
        />
      </Item>
      <Item>
        <Button type="primary" onClick={handleSubmit} text="Aceptar" />
      </Item>
    </Form>
  );
};
FormUser.propTypes = {
  initialValues: PropTypes.shape(),
  establishments: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func
};
FormUser.defaultProps = {
  onSubmit: () => {},
  establishments: [],
  initialValues: {}
};

export default FormUser;
