/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ text }) => (
  <div className="container-title">
    <p>{text}</p>
    <div className="container-title__subrayado" />
  </div>
);
Title.propTypes = {
  text: PropTypes.string,
};
Title.defaultProps = {
  text: ''
};
export default Title;
