/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../redux/actions/app';
import FormLogin from './form';
import { ROOT_PAGE_PATH } from '../../routes/path';
import Card from '../../components/card';
import Spinner from '../../components/spinner';
import { getFirebaseToken } from '../../firebase';

const LoginForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('accessToken');

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROOT_PAGE_PATH);
    }
  }, [isAuthenticated, navigate]);

  const handleSuccess = () => {
    navigate(ROOT_PAGE_PATH);
    getFirebaseToken();
  };

  const handleSubmmit = (values) => {
    setLoading(true);
    const loginForm = { email: values.email.toLowerCase(), ...values };
    dispatch(login(loginForm, handleSuccess));
    setLoading(false);
  };

  if (isAuthenticated) {
    return <Spinner />;
  }

  return (
    <div className="container-page__login">
      <Card title="Inicio de sesión" login>
        <FormLogin form={form} loading={loading} onSubmmit={handleSubmmit} />
      </Card>
    </div>
  );
};

export default LoginForm;
