/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Divider, Modal, Popconfirm
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../components/title';
import Button from '../../components/button';
import withAuth from '../../hoc';
import {
  deleteEstablishmentById,
  // getAllEstablishments,
  getEstablishmentById,
  updateEstablishmentById
} from '../../redux/actions/establishment';
import Module from './module';
import FormDetailEstablishment from './form';
import Spinner from '../../components/spinner';
import Historical from './historical';
import Campaign from './campaign';
import User from './user';
import { ESTABLISHMENTS_PATH } from '../../routes/path';
import Croppoint from './croppoint';

const DetailEstablishment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const rol = localStorage.getItem('rol');
  const { establishment, isLoading } = useSelector((state) => state.establishmentReducer);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('all');
  const navigate = useNavigate();

  const openShowModal = () => {
    setShowModal(true);
  };
  const closeShowModal = () => {
    setShowModal(false);
  };

  const getData = () => {
    dispatch(getEstablishmentById(id));
    // dispatch(getAllEstablishments());
    closeShowModal();
  };

  const handleEditEstablishment = (data) => {
    dispatch(updateEstablishmentById(id, data, getData));
  };

  const handleChange = (status) => {
    setStatus(status);
  };

  const returnPage = () => {
    navigate(ESTABLISHMENTS_PATH);
  };

  const handleDelete = (id) => {
    dispatch(deleteEstablishmentById(id, returnPage));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {isLoading ? <Spinner /> : (
        <div className="container-page">
          <div className="container-page__header">
            <div>
              <Title text={establishment.nombre} />
              <p className="container-page__id">{`ID: ${id}`}</p>
            </div>

            {rol !== 'user' && (
              <div>
                <Button
                  text="Editar establecimiento"
                  color="transparent"
                  icon={<SettingOutlined />}
                  onClick={openShowModal}
                />
                <Popconfirm
                  placement="bottom"
                  title="¿Desea eliminar el establecimiento?"
                  onConfirm={() => handleDelete(id)}
                  okText="Si"
                  cancelText="No"
                >
                  <Button type="primary" icon={<DeleteOutlined />} text="Eliminar establecimiento" color="transparent" />
                </Popconfirm>
              </div>
            )}
          </div>
          <Divider />
          {
            isLoading ? <Spinner />
              : (
                <>
                  <Croppoint
                    cropprofiles={establishment?.cropprofiles}
                    getData={getData}
                  />
                  <Divider />
                  <User
                    idEstablishment={id}
                  />
                  <Module
                    getData={getData}
                    id={id}
                    establishment={establishment}
                  />
                  <Campaign />
                  <Divider />
                  {establishment.modulos && (
                    <Historical
                      modules={establishment.modulos}
                      status={status}
                      onChange={handleChange}
                    />
                  )}
                </>
              )
          }
          <Modal
            title="Editar establecimiento"
            open={showModal}
            onOk={closeShowModal}
            onCancel={closeShowModal}
            footer={null}
          >
            <FormDetailEstablishment
              initialValues={establishment}
              onSubmit={handleEditEstablishment}
              isLoading={isLoading}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default withAuth(DetailEstablishment);
