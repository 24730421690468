const parseRol = (rol) => {
  if (rol === 'superadmin') return 'Super admin';
  if (rol === 'admin_establishment') return 'Admin';
  return 'Usuario';
};

export const parserUser = ({
  _id, nombre, apellido, email, rol, establecimientos
}) => ({
  key: _id,
  nombre,
  apellido,
  email,
  rol: parseRol(rol),
  rolId: rol,
  establecimientos
});

export const parserUserList = (list) => list?.map((user) => parserUser(user));
