/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Select, Switch, Row, Col
} from 'antd';
import { getValidationRules } from '../../../../utils/validations';
import { parserSelectProfile } from '../../../../utils/parsers/crop-profile';
import Input from '../../../../components/input';
import Button from '../../../../components/button';

const { Item } = Form;

const FormModule = ({
  onSubmit, initialValues, cropprofiles, establishment
}) => {
  const [form] = Form.useForm();
  const [circulacionAire, setCirculacionAire] = useState(initialValues.circulacionAire);
  const [humificadores, setHumificadores] = useState(initialValues.aireAcondicionado);
  const [generadores, setGeneradores] = useState(initialValues.generadorCO);

  const handleCirculacionAireChange = (checked) => {
    setCirculacionAire(checked);
  };

  const handleHumificadoresChange = (checked) => {
    setHumificadores(checked);
  };

  const handleGeneradoresChange = (checked) => {
    setGeneradores(checked);
  };

  const onFinish = (values) => {
    console.log(values);
    onSubmit({ key: initialValues._id, ...values });
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
      form.resetFields();
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...establishment,
      perfilcultivo: initialValues?.perfilcultivo && initialValues?.perfilcultivo,
      initialValues
    });
  }, [initialValues]);

  return (
    <div>
      {initialValues
        ? (
          <Form form={form} layout="vertical" initialValues={initialValues} className="form-container">
            <Row gutter={16} className="row-container">
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="numero"
                  rules={getValidationRules('número')}
                  label="Número"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={16}>
                <Item
                  className="form-container__item"
                  name="perfilcultivo"
                  label="Perfil de cultivo"
                >
                  <Select
                    disabled={!cropprofiles}
                    options={cropprofiles?.length !== 0 ? parserSelectProfile(cropprofiles) : []}
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="superficieTotal"
                  label="Superficie total (m2)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="superficieCultivo"
                  label="Superficie cultivo (m2)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="volumenTotal"
                  label="Volumen total (m3)"
                >
                  <Input type="number" />
                </Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="intraccionAire"
                  label="Intracción aire (m3/h)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="extraccionAire"
                  label="Extracción aire (m3/h)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  className="form-container__item"
                  name="luminaria"
                  label="Luces watts/m2"
                >
                  <Input type="number" />
                </Item>
              </Col>
            </Row>
            <Row gutter={16} className="row-container">
              <Col span={3}>
                <div className="switch-direction">
                  <Item
                    name="circulacionAire"
                  >
                    <Switch
                      checked={circulacionAire}
                      onChange={handleCirculacionAireChange}
                    />
                  </Item>
                  <p>Circulaciones de aire</p>
                </div>
                <div className="switch-direction">
                  <Item
                    name="aireAcondicionado"
                  >
                    <Switch
                      checked={humificadores}
                      onChange={handleHumificadoresChange}
                    />
                  </Item>
                  <p>Humificadores</p>
                </div>
                <div className="switch-direction">
                  <Item
                    name="generadorCO"
                  >
                    <Switch
                      checked={generadores}
                      onChange={handleGeneradoresChange}
                    />
                  </Item>
                  <p>Generadores de CO2</p>
                </div>
              </Col>
              <Col span={8} className="col-8-edit">
                {humificadores === true
         && (
         <Item
           className="form-container__item"
           name="frigorias"
           label="A/C (frigorías)"
         >
           <Input type="number" />
         </Item>
         )}
              </Col>
            </Row>
            <Item>
              <Button type="primary" onClick={handleSubmit} text="Guardar cambios" />
            </Item>
          </Form>
        )
        : <p>No se encontraron datos iniciales.</p>}
    </div>

  );
};

FormModule.propTypes = {
  initialValues: PropTypes.shape(),
  cropprofiles: PropTypes.arrayOf(PropTypes.shape()),
  establishment: PropTypes.shape(),
  onSubmit: PropTypes.func
};

FormModule.defaultProps = {
  onSubmit: () => {},
  establishment: {},
  cropprofiles: [],
  initialValues: {}
};

export default FormModule;
