/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import Card from '../../card';
import Icon from '../../icon';
import SpinnerInto from '../../spinner-inner-components';

const ChangeCropProfile = ({
  cropprofiles, onChange, idModule, cropprofile, isLoading
}) => (
  <div className="container-page__card-center">
    <p>Selecciona el perfil de cultivo que desees cambiar.</p>
    <div style={{ height: '20rem', overflowY: 'auto' }}>
      {isLoading ? <SpinnerInto />
        : (cropprofiles.map(({ _id, nombre }) => (
          <div>
            {cropprofile?._id === _id ? (
              <Card
                style={{
                  margin: '10px',
                  marginInline: '70px',
                  backgroundColor: '#ECEFEE',
                  cursor: 'not-allowed',
                }}
              >
                <Icon name="Plantacion" />
                <p className="card-info__establishment">{nombre}</p>
              </Card>
            )
              : (
                <Popconfirm
                  placement="center"
                  title="¿Desea cambiar el perfil de cultivo?"
                  onConfirm={() => onChange(idModule, _id)}
                  okText="Si"
                  key={_id}
                  cancelText="No"
                  disabled={cropprofile?._id === _id}
                >
                  <Card
                    style={{
                      margin: '10px',
                      marginInline: '70px',
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <Icon name="Plantacion" />
                    <p className="card-info__establishment">{nombre}</p>
                  </Card>
                </Popconfirm>
              )}
          </div>
        )))}
    </div>
  </div>
);

ChangeCropProfile.propTypes = {
  idModule: PropTypes.string,
  cropprofiles: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  cropprofile: PropTypes.shape(),
  isLoading: PropTypes.bool,
};

ChangeCropProfile.defaultProps = {
  onChange: () => {},
  idModule: '',
  cropprofiles: [],
  cropprofile: {},
  isLoading: false
};

export default ChangeCropProfile;
