/* eslint-disable no-undef */
/* eslint-disable no-promise-executor-return */
import {
  Drawer, Menu, Popconfirm, Popover
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import Button from '../../../button';
import Icon from '../../../icon';
import { menu, menuRolUser } from '../../../../utils/menu-nav';
import { ROOT_PAGE_PATH } from '../../../../routes/path';
import LogoHemp from '../../../../assets/img/logoHemp.png';

const HeaderMobile = ({
  nombre, onLogout, notificationPermission,
  notificacion, onNotifications, openModal
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const token = localStorage.getItem('accessToken');
  const rol = localStorage.getItem('rol');

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await new Promise((resolve) => setTimeout(window.location.reload(resolve), 1000));
    setRefreshing(false);
  };
  useEffect(() => {
    handleMenuClose();
  }, [pathname]);
  return (
    <Header>
      <PullToRefresh
        onRefresh={handleRefresh}
        refreshing={refreshing && isMobile}
        isPullable={isMobile}
        pullDownContent=""
        maxPullDownDistance={120}
      >
        {token && rol && (
          <div className="menu-mobile__container">
            <div className="menu-mobile__user">
              <Popconfirm
                placement="bottomLeft"
                title={`¿Desea cerrar la sesión ${nombre}?`}
                onConfirm={onLogout}
                width="10rem"
                okText="Si"
                cancelText="No"
                style={{ width: '10rem' }}
              >
                <Button
                  icon={<Icon name="User" size={50} />}
                  color="transparent"
                />
              </Popconfirm>
            </div>
            <div className="menu-mobile__icon">
              <Link to={ROOT_PAGE_PATH}>
                <img src={LogoHemp} alt="logo" />
              </Link>
            </div>
            <div className="menu-mobile__menu">
              {isMobile && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {notificationPermission === 'granted'
                    ? (
                      <Popconfirm
                        placement="left"
                        title={notificacion ? '¿Desea desactivar las notificaciones?' : '¿Desea activar las notificaciones?'}
                        onConfirm={onNotifications}
                        width="10rem"
                        okText="Si"
                        cancelText="No"
                        style={{ width: '10rem' }}
                      >
                        {
                 rol !== 'superadmin' && (
                 <div style={{ backgroundColor: 'transparent' }}>
                   <Button
                     icon={notificacion
                       ? <Icon name="Bell" size={25} />
                       : <Icon name="DisableWhite" size={25} />}
                     color="transparent"
                   />
                 </div>
                 )
                  }
                      </Popconfirm>
                    )
                    : (
                      <Popover
                        title="Debe activar los permisos en la aplicacion."
                        placement="left"
                      >
                        <div style={{ marginTop: '.8rem', marginLeft: '-1rem' }}>
                          {
                 rol !== 'superadmin' && (
                 <Button
                   icon={<Icon name="DisableBlack" size={25} />}
                   color="transparent"
                   onClick={openModal}
                 />
                 )
                  }
                        </div>
                      </Popover>
                    )}
                  <Button
                    className="menu-burger"
                    icon={<MenuOutlined />}
                    type="text"
                    onClick={handleMenuOpen}
                    style={{
                      color: 'white',
                      backgroundColor: 'transparent'
                    }}
                  />
                  <Drawer
                    title="Menu"
                    placement="right"
                    onClose={handleMenuClose}
                    open={isMenuOpen}
                    className="menu-drawer"
                  >
                    <Menu
                      selectedKeys={pathname}
                      mode="vertical"
                      items={rol !== 'user' ? menu : menuRolUser}
                    />
                  </Drawer>
                </div>
              )}
            </div>
          </div>
        )}
      </PullToRefresh>
    </Header>
  );
};

HeaderMobile.propTypes = {
  nombre: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  notificationPermission: PropTypes.string.isRequired,
  notificacion: PropTypes.bool.isRequired,
  onNotifications: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};
export default HeaderMobile;
