/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Modal, Pagination, Popconfirm, Space
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../components/button';
import Table from '../../../components/table';
import withAuth from '../../../hoc';
import { columnsUser } from '../../../utils/table-parser';
import FormUser from './form';
import {
  deleteUserById,
  getAllUsersEstablishment,
  updateUserById,
  createUser,
  cleanUser
} from '../../../redux/actions/user';
import { parserUserList } from '../../../utils/parsers/user';
import { parserSelect } from '../../../utils/parsers/establishment';
import { usePagination } from '../../../hooks/usePagination';

const initialValues = {
  nombre: '',
  apellido: '',
  email: '',
  rol: '',
  establecimientos: []
};

const User = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    dataSource, handlePage, page, total
  } = usePagination(getAllUsersEstablishment, 'user', id);

  const { establishments } = useSelector((state) => state.establishmentReducer);
  const rol = localStorage.getItem('rol');

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);
  const [form, setForm] = useState();

  const getDataUsers = () => {
    handlePage(1);
    dispatch(getAllUsersEstablishment(10, 0, id));
  };

  const openModalEdit = () => {
    setShowModalEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
    getDataUsers();
  };

  const handleShowModalEdit = (data) => {
    setForm(data);
    openModalEdit();
  };

  const openModalNew = () => {
    setShowModalNew(true);
  };

  const closeModalNew = () => {
    setShowModalNew(false);
    getDataUsers();
  };

  const handleShowModalNew = () => {
    openModalNew();
  };

  const handleEdit = ({ key, ...data }) => {
    dispatch(updateUserById(key, data));
    getDataUsers();
    closeModalEdit();
  };

  const handleCreate = (data) => {
    dispatch(createUser(data, closeModalNew));
  };

  const handleDelete = (id) => {
    dispatch(deleteUserById(id, getDataUsers));
  };
  useEffect(() => () => {
    dispatch(cleanUser());
  }, []);

  const handleActionsColumn = (user) => (
    <Space size="middle">
      {
        user.rol !== 'Super admin' && (
        <>
          <Button
            type="primary"
            onClick={() => {
              handleShowModalEdit(user);
            }}
            icon={<EditOutlined />}
            text="Editar"
            color="transparent"
          />
          {(rol === 'admin_establishment' && user.rol === 'Admin')
            ? null
            : (
              <Popconfirm
                placement="left"
                title={`¿Desea eliminar a ${user.nombre}?`}
                onConfirm={() => handleDelete(user.key)}
                okText="Si"
                cancelText="No"
              >
                <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
              </Popconfirm>
            )}
        </>
        )
      }
    </Space>
  );

  return (
    <div>
      <p className="container-page__subtitle">Usuarios</p>
      <div className="container-page__button-new">
        {rol !== 'user' && (
        <Button
          text="Agregar usuario +"
          color="transparent"
          onClick={handleShowModalNew}
        />
        )}
      </div>
      <div className="container-page__table">
        <Table
          columns={columnsUser}
          data={dataSource && parserUserList(dataSource)}
          actionsColumn={handleActionsColumn}
          actions={rol !== 'user'}
        />
        <div className="container-page__pagination">
          <Pagination
            current={page}
            onChange={handlePage}
            total={total}
          />
        </div>
      </div>
      <Modal
        title="Editar usuario"
        open={showModalEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <FormUser
          initialValues={form}
          onSubmit={handleEdit}
          establishments={parserSelect(establishments)}
        />
      </Modal>
      <Modal
        title="Crear nuevo usuario"
        open={showModalNew}
        onOk={closeModalNew}
        onCancel={closeModalNew}
        footer={null}
      >
        <FormUser
          initialValues={initialValues}
          onSubmit={handleCreate}
          establishments={parserSelect(establishments)}
        />
      </Modal>
    </div>
  );
};
export default withAuth(User);
