import { handle } from 'redux-pack';
import {
  CLEAN_SENSOR,
  DELETE_SENSOR,
  GET_SENSOR,
  GET_SENSOR_BY_ID,
  GET_SENSOR_BY_TYPE,
  GET_SENSOR_TYPES,
  GET_UNIT,
} from '../actions/types';
import { handleAction } from '../../utils/handleAction';

const initialState = {
  sensor: {},
  sensors: [],
  sensorProfile: {},
  isLoading: false,
  isLoadingSensor: false
};
const sensorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SENSOR:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          sensors: payload.data
        }),
      });
    case DELETE_SENSOR:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          cropprofiles: payload.data
        }),
      });
    case GET_SENSOR_BY_TYPE:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensor: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false, isLoadingSensor: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensors: payload.data
        }),
      });
    case GET_UNIT:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          units: payload.data
        }),
      });
    case GET_SENSOR_TYPES:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: false }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload.message }),
        success: (prevState) => ({
          ...prevState,
          sensorTypes: payload.data
        }),
      });
    case GET_SENSOR_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          sensor: payload.data
        }),
      });
    case CLEAN_SENSOR:
      return { ...state, sensor: {}, sensors: [] };
    default:
      return state;
  }
};
export default sensorReducer;
