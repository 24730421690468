/* eslint-disable import/no-cycle */
import {
  SENSOR_API_PATH,
  SENSOR_BY_TYPES_API_PATH,
  SENSOR_TYPES_API_PATH,
  SENSOR_VALUES_API_PATH,
  UNIT_API_PATH
} from '../../service/path';
import {
  CLEAN_SENSOR,
  CREATE_SENSOR,
  DELETE_SENSOR,
  GET_SENSOR,
  GET_SENSOR_BY_ID,
  GET_SENSOR_BY_TYPE,
  GET_SENSOR_TYPES,
  GET_SENSOR_VALUES,
  GET_UNIT,
  UPDATE_SENSOR
} from './types';
import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const getAllSensors = (perPage, offSet) => (dispatch) => dispatch({
  type: GET_SENSOR,
  promise: api.get(`${SENSOR_API_PATH}?perPage=${perPage}&offSet=${offSet}`),
});

export const getSensorById = (id) => (dispatch) => dispatch({
  type: GET_SENSOR_BY_ID,
  promise: api.get(`${SENSOR_API_PATH}/${id}`)
});

export const getSensorByValues = (perPage, offSet, id, modulo) => (dispatch) => dispatch({
  type: GET_SENSOR_VALUES,
  promise: api.get(`${SENSOR_VALUES_API_PATH}/${id}?perPage=${perPage}&offSet=${offSet}&modulo=${modulo}`)
});

export const getSensorByType = (id) => (dispatch) => dispatch({
  type: GET_SENSOR_BY_TYPE,
  promise: api.get(`${SENSOR_BY_TYPES_API_PATH}/${id}`)
});

export const createSensor = (data, callback) => (dispatch) => dispatch({
  type: CREATE_SENSOR,
  promise: api.post(SENSOR_API_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El sensor fue creado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteSensorById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_SENSOR,
  promise: api.delete(`${SENSOR_API_PATH}/${id}`),
  meta: {
    onSuccess: (res) => {
      if (res.data.statusCode === 200) {
        showSuccessMessage('El sensor fue eliminado correctamente', dispatch);
      }
      callback(res.data.statusCode);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateSensorById = (id, data, callback) => (dispatch) => dispatch({
  type: UPDATE_SENSOR,
  promise: api.put(`${SENSOR_API_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El sensor fue editado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const cleanSensor = () => (dispatch) => dispatch({
  type: CLEAN_SENSOR,
});

// unidad
export const getAllUnits = () => (dispatch) => dispatch({
  type: GET_UNIT,
  promise: api.get(UNIT_API_PATH),
});

// tipo de sensor
export const getAllSensorTypes = () => (dispatch) => dispatch({
  type: GET_SENSOR_TYPES,
  promise: api.get(SENSOR_TYPES_API_PATH),
});
