/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Modal, Popconfirm, Space
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../components/button';
import Title from '../../components/title';
import withAuth from '../../hoc';
import {
  cleanSensor,
  deleteSensorById,
  getAllSensors,
  updateSensorById,
  createSensor,
  getAllUnits,
  getAllSensorTypes
} from '../../redux/actions/sensor';
import Spinner from '../../components/spinner';
import { usePagination } from '../../hooks/usePagination';
import FormSensor from './form';
import { CROP_PROFILE_PATH } from '../../routes/path';
import { parserSelect } from '../../utils/parsers/establishment';
import { getAllEstablishments } from '../../redux/actions/establishment';
import TableSensor from './table-sensor';

const initialValues = {
  nombre: '',
  apellido: '',
  email: '',
  rol: '',
  establecimientos: []
};

const Sensor = () => {
  const dispatch = useDispatch();
  const {
    dataSource, handlePage, page, total
  } = usePagination(getAllSensors, 'sensor');

  const {
    units, sensorTypes, isLoading, cropprofiles
  } = useSelector((state) => state.sensorReducer);
  const { establishments } = useSelector((state) => state.establishmentReducer);
  const rol = localStorage.getItem('rol');

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalCrop, setShowModalCrop] = useState(false);
  const [form, setForm] = useState();
  const [selectDefault, setSelectDefault] = useState([]);

  const getData = (statusCode) => {
    handlePage(1);
    dispatch(getAllSensors(10, 0));
    if (statusCode === 400) setShowModalCrop(true);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModalEdit = () => {
    setShowModalEdit(true);
  };

  const handleShowModalEdit = (data) => {
    setForm(data);
    dispatch(getAllUnits());
    dispatch(getAllSensorTypes());
    openModalEdit();
    setSelectDefault(data?.establecimientos);
  };

  const closeModalNew = () => {
    setShowModalNew(false);
  };

  const closeModalCrop = () => {
    setShowModalCrop(false);
  };

  const openModalNew = () => {
    setShowModalNew(true);
  };

  const handleShowModalNew = () => {
    openModalNew();
    dispatch(getAllUnits());
    dispatch(getAllSensorTypes());
  };

  const handleEdit = ({ key, ...data }) => {
    dispatch(updateSensorById(key, data, getData));
    closeModalEdit();
  };

  const handleCreate = ({ key, ...data }) => {
    dispatch(createSensor(data, getData));
    closeModalNew();
  };

  const handleDelete = (id) => {
    dispatch(deleteSensorById(id, getData));
  };

  const handleActionsColumn = (sensor) => (
    <Space size="middle">
      <Button
        type="primary"
        onClick={() => handleShowModalEdit(sensor)}
        icon={<EditOutlined />}
        text="Editar"
        color="transparent"
      />
      <Popconfirm
        placement="left"
        title={`¿Desea eliminar a ${sensor?.nombre}?`}
        onConfirm={() => handleDelete(sensor?.key)}
        okText="Si"
        cancelText="No"
      >
        <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
      </Popconfirm>
    </Space>
  );

  useEffect(() => {
    dispatch(getAllEstablishments());
    return () => {
      dispatch(cleanSensor());
    };
  }, []);
  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Sensores" />
        {rol !== 'user' && <Button onClick={handleShowModalNew} text="Agregar sensor +" color="transparent" />}
      </div>
      {isLoading
        ? <Spinner />
        : (
          <TableSensor
            rol={rol}
            handleActionsColumn={handleActionsColumn}
            dataSource={dataSource}
            handlePage={handlePage}
            page={page}
            total={total}
          />
        )}
      <Modal
        title="Editar sensor"
        open={showModalEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <FormSensor
          initialValues={form}
          onSubmit={handleEdit}
          units={units}
          types={sensorTypes}
          establishments={parserSelect(establishments)}
          selectDefault={selectDefault}
        />
      </Modal>
      <Modal
        title="Crear nuevo sensor"
        open={showModalNew}
        onOk={closeModalNew}
        onCancel={closeModalNew}
        footer={null}
      >
        <FormSensor
          initialValues={initialValues}
          onSubmit={handleCreate}
          units={units}
          types={sensorTypes}
          establishments={parserSelect(establishments)}
          selectDefault={selectDefault}
        />
      </Modal>
      <Modal
        title="No se puede eliminar, sensor asociado a un perfil de cultivo"
        open={showModalCrop}
        onOk={closeModalCrop}
        onCancel={closeModalCrop}
        footer={null}
      >
        <div>
          {cropprofiles?.data.map(({ id, nombre }) => (
            <div key={id} className="error-modal">
              <p>{nombre}</p>
              <Link target="_blank" to={`${CROP_PROFILE_PATH}/${id}`}>Ir al perfil de cultivo</Link>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};
export default withAuth(Sensor);
