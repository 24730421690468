/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomArrow from './arrows';

const CarouselComponent = ({ children, countToShow }) => {
  const settings = {
    prevArrow: <CustomArrow icon="left" size={30} />,
    nextArrow: <CustomArrow icon="right" size={30} />,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: countToShow < 3 ? countToShow : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          prevArrow: <CustomArrow icon="left" size={20} color="white" position={5} />,
          nextArrow: <CustomArrow icon="right" size={20} color="white" position={5} />,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {children}
    </Slider>
  );
};

CarouselComponent.propTypes = {
  children: PropTypes.node.isRequired,
  countToShow: PropTypes.number.isRequired,
};
export default CarouselComponent;
