import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const usePagination = (dispatchFunc, reducer, id, status) => {
  const [page, setPage] = useState(1);
  const [isStatus, setIsStatus] = useState(false);
  const dispatch = useDispatch();
  const perPage = 10;
  useEffect(() => {
    setIsStatus(false);
    const offset = (page - 1) * perPage;
    if (id) {
      if (status !== undefined) {
        dispatch(dispatchFunc(10, offset, id, status));
        setIsStatus(true);
      } else {
        dispatch(dispatchFunc(10, offset, id));
      }
    } else {
      dispatch(dispatchFunc(10, offset));
    }
  }, [page, status, id]);
  const onChangeStatus = (newStatus) => {
    if (!isStatus) {
      const offset = (page - 1) * perPage;
      dispatch(dispatchFunc(10, offset, id, newStatus));
    }
  };
  const total = useSelector((state) => state[`${reducer}Reducer`][`${reducer}s`]?.total);
  const isLoading = useSelector((state) => state[`${reducer}Reducer`]?.isLoading);
  const dataSource = useSelector((state) => state[`${reducer}Reducer`][`${reducer}s`]?.data);
  const handlePage = (e) => {
    setPage(e);
  };
  return {
    dataSource,
    total,
    handlePage,
    page,
    setPage,
    isLoading,
    onChangeStatus
  };
};
