/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Space
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Input from '../../../../components/input';
import Button from '../../../../components/button';

const { Item } = Form;

const CampaingForm = ({
  onSubmit, initialValues,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ initialValues });
  }, [initialValues]);
  return (
    <div>
      {initialValues
        ? (
          <Form form={form} layout="vertical" initialValues={initialValues} className="form-container">
            <Row gutter={24} className="row-container">
              <Col span={10}>
                <Item
                  className="form-container__item"
                  name="cantPlantas"
                  label="Plantas por m2 (unidades)"
                >
                  <Input type="number" />
                </Item>
              </Col>
              <Col span={10}>
                <Item
                  className="form-container__item"
                  name="volMacetas"
                  label="Volumen por macetas (litros)"
                >
                  <Input type="number" />
                </Item>
              </Col>
            </Row>
            <div className="form-variedades">
              <p>Variedades</p>
              <div className="line-variedades" />
              <Form.List name="variables">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'nombre']}
                          label="Nombre"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'bancoSemillas']}
                          label="Banco de semilla"
                        >
                          <Input />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        style={{ backgroundColor: 'transparent', color: 'black', border: 'none' }}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <Item>
              <Button type="primary" onClick={handleSubmit} text="Guardar cambios" />
            </Item>
          </Form>
        )
        : <p>No se encontraron datos iniciales.</p>}
    </div>

  );
};

CampaingForm.propTypes = {
  initialValues: PropTypes.shape(),
  establishment: PropTypes.shape(),
  onSubmit: PropTypes.func
};

CampaingForm.defaultProps = {
  onSubmit: () => {},
  establishment: {},
  initialValues: {}
};

export default CampaingForm;
