/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';
import CardColor from '../../card-color';
import Button from '../../button';
import { REPORTS_PATH } from '../../../routes/path';
import CarouselCard from '../../carrusel/card-carousel';
import {
  cleanCropProfile, getAllCropProfilesModulo, getCropProfileByModuleId
} from '../../../redux/actions/crop-profile';
import InfoParameter from '../info-parameters';
import InfoSensor from '../info-sensor';
import { createBinnacle, getAllBinnacleTypes } from '../../../redux/actions/binnacles';
import Icon from '../../icon';
import ChangeCropProfile from '../change-crop';
import FormAcontecimientos from '../form-acontecimiento';
import FormCosecha from '../form-cosecha';
import { updateModuleById } from '../../../redux/actions/module';
import { api } from '../../../service/api';
import { CROP_PROFILE_API_PATH } from '../../../service/path';
import { getAllEstablishmentsPanelNotLoading } from '../../../redux/actions/home';

const Modulos = ({
  modules, wifi, idEstablishment
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [numberModule, setNumberModule] = useState();
  // const [cropprofile, setCropprofile] = useState();
  const [showSensors, setShowSensors] = useState(false);
  const [showInformacion, setShowInformacion] = useState(false);
  const [infoSensor, setInfoSensor] = useState();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [moduleId, setModuleId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenBinnacle, setIsModalOpenBinnacle] = useState(false);
  const [isModalOpenCloseCrop, setIsModalOpenCloseCrop] = useState(false);
  const [isModalOpenRecomendacion, setIsModalOpenRecomendacion] = useState(false);
  const [isModalOpenAlerta, setIsModalOpenAlerta] = useState(false);
  const [cropprofile, setCropprofile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [idCrop, setIdCrop] = useState();
  const [colorConnection, setColorConnection] = useState();

  const { cropprofiles } = useSelector((state) => state.cropprofileReducer);
  const { binnacles } = useSelector((state) => state.binnacleReducer);
  const colorClass = () => {
    if (wifi === 'rojo') setColorConnection('ConnectionRed');
    else if (wifi === 'amarillo') setColorConnection('ConnectionYellow');
    else if (wifi === 'verde') setColorConnection('ConnectionGreen');
    else setColorConnection('Connection');
  };
  const getColorCard = (id) => {
    const modulesSensors = modules?.map(({ _id, perfilcultivo }) => {
      if (perfilcultivo) {
        return perfilcultivo?.parametros?.map(
          ({ sensor }) => ({ modulo: _id, sensor })
        );
      }
      return _id;
    });

    const color = modulesSensors
      .flat(2)
      .filter(({ modulo }) => modulo === id)
      .map(({ sensor }) => {
        if (sensor.estado === 'Critico bajo' || sensor.estado === 'Critico alto') return 'red';
        if (sensor.estado === 'No óptimo bajo' || sensor.estado === 'No óptimo alto') return 'yellow';
        return 'green';
      });
    if (color.includes('red')) return 'red';
    if (color.includes('yellow')) return 'yellow';
    return 'green';
  };

  /* Redirecciona a reportes */
  const viewReports = () => {
    navigate(`${REPORTS_PATH}/${idEstablishment}`);
  };

  const viewParameters = () => {
    setShowSensors(true);
  };

  /* Funcion que muestra un perfil de cultivo */
  const showInfoCropProfile = (id, number, idModule) => {
    setNumberModule(number);
    if (id === undefined) {
      setCropprofile(null);
    } else dispatch(getCropProfileByModuleId(id, idModule));
    viewParameters();
    setModuleId(idModule);
    setIdCrop(id);
  };

  /* Navegacion back a los modulos */
  const hiddenParameters = () => {
    setShowSensors(false);
    dispatch(cleanCropProfile());
  };

  const hiddenParametersBack = () => {
    setShowSensors(false);
    dispatch(cleanCropProfile());
    dispatch(getAllEstablishmentsPanelNotLoading());
  };

  /* Mostrar la informacion de sensor */
  const viewSensor = () => {
    setShowInformacion(true);
  };

  const showInfoSensor = (id) => {
    const sensorParameter = cropprofile.parametros?.find(({ _id }) => _id === id);
    setInfoSensor({ cropprofile: cropprofile.nombre, ...sensorParameter });
    viewSensor();
  };

  /* Navegacion back a los parametros */
  const hiddenSensor = () => {
    setShowInformacion(false);
  };

  // #region modales

  /* Botones en footer en parametros */
  const showModalCroppoint = () => {
    setIsModalOpen(true);
    dispatch(getAllCropProfilesModulo(idEstablishment));
  };
  const showModalBinnacle = () => {
    setIsModalOpenBinnacle(true);
    dispatch(getAllBinnacleTypes());
  };
  const showModalCloseCrop = () => {
    setIsModalOpenCloseCrop(true);
  };
  const showModalRecomendacion = () => {
    setIsModalOpenRecomendacion(true);
  };
  const showModalAlerta = () => {
    setIsModalOpenAlerta(true);
  };

  const iconButtons = [
    {
      id: 'CambiodePerfil', func: showModalCroppoint, disabled: false
    },
    {
      id: 'Cosecha', func: showModalBinnacle, disabled: !cropprofile?.campaña
    },
    {
      id: 'IngresoManual', func: showModalCloseCrop, disabled: !cropprofile?.campaña
    },
    {
      id: 'Alerta', func: showModalAlerta, disabled: false
    },
    {
      id: 'Recomendacion', func: showModalRecomendacion, disabled: false
    },
  ];

  const iconButtonsNotSuperPerfil = [
    {
      id: 'CambiodePerfil', func: showModalCroppoint, disabled: false
    },
    {
      id: 'Cosecha', func: showModalBinnacle, disabled: !cropprofile?.campaña
    },
    {
      id: 'IngresoManual', func: showModalCloseCrop, disabled: !cropprofile?.campaña
    },

  ];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCloseModalChangeCrop = () => {
    setIsModalOpen(false);
    hiddenParametersBack();
  };

  const handleCancelBinnacle = () => {
    setIsModalOpenBinnacle(false);
  };

  const handleCancelCrop = () => {
    setIsModalOpenCloseCrop(false);
  };

  const handleCloseCrop = () => {
    setIsModalOpenCloseCrop(false);
    hiddenParametersBack();
  };

  const handleCancelRecomendacion = () => {
    setIsModalOpenRecomendacion(false);
  };

  const handleCancelAlerta = () => {
    setIsModalOpenAlerta(false);
  };

  const handleChangeModule = (id, idperfilcultivo) => {
    const newObject = { perfilcultivo: idperfilcultivo };
    dispatch(updateModuleById(id, newObject, handleCloseModalChangeCrop));
  };
  const handleBinnacleOk = (newAcontecimiento) => {
    dispatch(createBinnacle(newAcontecimiento, handleCancelBinnacle));
  };

  const handleCloseCropOk = (values) => {
    dispatch(createBinnacle({ ...values, campaña: cropprofile?.campaña?._id }, handleCloseCrop));
  };
  // #endregion

  /*  */
  useEffect(() => {
    colorClass();
    let intervalId;
    if (showSensors && idCrop) {
      setIsLoading(true);
      api.get(`${CROP_PROFILE_API_PATH}/${idCrop}/${moduleId}`)
        .then(((res) => { setCropprofile(res.data); setIsLoading(false); colorClass(); }));
      intervalId = setInterval(() => api.get(`${CROP_PROFILE_API_PATH}/${idCrop}/${moduleId}`)
        .then(((res) => setCropprofile(res.data))), 10000);
    } else if (!showSensors) {
      clearInterval(intervalId);
      setCropprofile(null);
    }
    return () => {
      clearInterval(intervalId);
      setCropprofile(null);
    };
  }, [idCrop, showSensors]);
  useEffect(() => { colorClass(); }, [wifi]);
  return (
    <>
      {
        modules?.length !== 0
          ? (
            <>
              <div className="card-body">
                {!showSensors
                  ? (
                    <div className="modulos">
                      <CarouselCard styles="slick-grid" countToShow={modules.length}>
                        {modules?.map(({ numero, perfilcultivo, _id }) => (
                          <CardColor
                            key={_id}
                            value={perfilcultivo ? numero : numero}
                            color={perfilcultivo ? getColorCard(_id) : 'grey'}
                            onClick={() => showInfoCropProfile(perfilcultivo?._id, numero, _id)}
                          />
                        ))}
                      </CarouselCard>
                    </div>
                  ) : (
                    <>
                      <div className="parametros">
                        {!showInformacion
                          ? (
                            <InfoParameter
                              title={numberModule}
                              idModule={moduleId}
                              onHiddenParameters={hiddenParameters}
                              onViewInfoSensor={showInfoSensor}
                              cropprofile={cropprofile}
                              isLoading={isLoading}
                              open={isModalOpenAlerta}
                              cancel={handleCancelAlerta}
                            />
                          ) : (
                            <InfoSensor data={infoSensor} onHiddenSensor={hiddenSensor} />
                          )}
                      </div>
                    </>
                  )}
              </div>
              <div className={isMobile ? 'card-footer' : 'card-home-desktop__footer'}>
                {showSensors ? (
                  <>
                    <Button
                      color="transparent"
                      icon={<Icon name={colorConnection} />}
                    />

                    <Button
                      type="primary"
                      onClick={viewReports}
                      htmlType="button"
                      color="transparent"
                      icon={<Icon name="Reporte" />}

                    />
                    {cropprofile?.superPerfil === 'Si' ? iconButtons.map(({
                      id, func, disabled
                    }) => (
                      <Button
                        key={id}
                        color="transparent"
                        disabled={disabled}
                        onClick={() => func()}
                        icon={<Icon name={id} />}
                      />
                    )) : iconButtonsNotSuperPerfil.map(({
                      id, func, disabled
                    }) => (
                      <Button
                        key={id}
                        color="transparent"
                        disabled={disabled}
                        onClick={() => func()}
                        icon={<Icon name={id} />}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <Button
                      color="transparent"
                      icon={<Icon name={colorConnection} />}
                    />

                    <Button
                      type="primary"
                      onClick={viewReports}
                      htmlType="button"
                      color="transparent"
                      icon={<Icon name="Reporte" />}

                    />
                  </>
                )}
              </div>
            </>
          )
          : (
            <>
              <div className="card-body">
                <div className="modulos">
                  <p className="messege-not-content">Sin modulos asignados</p>
                </div>
              </div>
              <div className={isMobile ? 'card-footer' : 'card-home-desktop__footer'}>
                <Button
                  color="transparent"
                  icon={<Icon name={colorConnection} />}
                />

                <Button
                  type="primary"
                  onClick={viewReports}
                  htmlType="button"
                  color="transparent"
                  icon={<Icon name="Reporte" />}
                />
              </div>
            </>
          )
      }
      <Modal
        title="Perfiles de cultivo"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
      >
        <ChangeCropProfile
          cropprofiles={cropprofiles}
          idModule={moduleId}
          onChange={handleChangeModule}
          cropprofile={cropprofile}
        />
      </Modal>
      <Modal
        title="Agregar acontecimiento"
        open={isModalOpenBinnacle}
        onOk={handleCancelBinnacle}
        onCancel={handleCancelBinnacle}
        footer={null}
      >
        <FormAcontecimientos
          onSubmit={handleBinnacleOk}
          cropprofile={cropprofile}
          binnacles={binnacles}
        />
      </Modal>
      <Modal
        title="Cierre de cosecha"
        open={isModalOpenCloseCrop}
        onOk={handleCloseCropOk}
        onCancel={handleCancelCrop}
        footer={null}
      >
        <FormCosecha
          onSubmit={handleCloseCropOk}
          cropprofile={cropprofile}
        />
      </Modal>
      <Modal
        title="Recomendacion"
        open={isModalOpenRecomendacion}
        onOk={handleCancelRecomendacion}
        onCancel={handleCancelRecomendacion}
        footer={null}
        className="modal-celphone"
      >
        <p>
          {cropprofile?.recomendacion ? cropprofile?.recomendacion : 'No se encontraron recomendaciones.'}
        </p>
      </Modal>
      <Modal
        title="Alerta"
        open={isModalOpenAlerta}
        onOk={handleCancelAlerta}
        onCancel={handleCancelAlerta}
        footer={null}
        className="modal-celphone"
      >
        <p>{cropprofile?.alerta ? cropprofile?.alerta : 'No se encontraron alertas.'}</p>
      </Modal>
    </>
  );
};
Modulos.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape()),
  wifi: PropTypes.string,
  idEstablishment: PropTypes.string,
};
Modulos.defaultProps = {
  modules: [],
  wifi: '',
  idEstablishment: '',
};
export default Modulos;
