/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { Modal, Popconfirm, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/table';
import Button from '../../../components/button';

import {
  deleteCampaignById,
  getAllCampaignsNotPagination, getCampaignById, removeCampaign, updateCampaignById
} from '../../../redux/actions/campaign';
import { columnsCampaign } from '../../../utils/table-parser';
import { parserCampaignList } from '../../../utils/parsers/campaign';
import { CAMPAING_DETAILS_PATH } from '../../../routes/path';
import CampaingForm from './form';

const Campaing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rol = localStorage.getItem('rol');
  const { campaign, campaigns, isLoading } = useSelector((state) => state.campaignReducer);
  const [showModalModuleEdit, setShowModalModuleEdit] = useState(false);
  const [idCampaign, setIdCampaign] = useState();

  // const initialValues = {
  //   nombre: '',
  //   bancoSemilla: '',
  //   cantPlantas: '',
  //   volMacetas: '',
  //   varieties: []
  // };

  const handleNavigate = (id) => {
    navigate(`${CAMPAING_DETAILS_PATH}/${id}`);
  };
  const getData = () => {
    dispatch(getAllCampaignsNotPagination(id));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteCampaignById(id, getData));
  };

  const openModalEdit = () => {
    setShowModalModuleEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalModuleEdit(false);
    dispatch(removeCampaign());
  };

  const handleModalEdit = async (data) => {
    await dispatch(getCampaignById(data.key));
    setIdCampaign(data.key);
    openModalEdit();
  };

  const onEditCampaing = (form) => {
    dispatch(updateCampaignById(idCampaign, form));
    closeModalEdit();
  };

  const actionColumn = (campaign) => (
    <Space>
      <Button color="transparent" icon={<EyeOutlined />} onClick={() => handleNavigate(campaign.key)} />
      <Button
        type="primary"
        icon={<EditOutlined />}
        color="transparent"
        onClick={() => handleModalEdit(campaign)}
      />
      <Popconfirm
        placement="left"
        title="¿Desea eliminar la campaña?"
        onConfirm={() => handleDelete(campaign.key)}
        okText="Si"
        cancelText="No"
      >
        {
       (rol !== 'user' && campaign.fechaDeCierre !== '-') && <Button type="primary" icon={<DeleteOutlined />} text="Eliminar" color="transparent" />
        }
      </Popconfirm>
    </Space>
  );
  return (
    <div>
      <p className="container-page__subtitle">Campañas</p>
      <div>
        <div className="container-page__table">
          <Table
            columns={columnsCampaign}
            data={parserCampaignList(campaigns)}
            actions
            actionsColumn={actionColumn}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Modal
        title="Propiedades de la campaña"
        open={showModalModuleEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <CampaingForm
          onSubmit={onEditCampaing}
          initialValues={campaign}
        />
      </Modal>
    </div>
  );
};
export default Campaing;
