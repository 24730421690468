import {
  CLEAN_MODULE,
  GET_MODULE_BY_ID,

} from '../actions/types';
import { handleAction } from '../../utils/handleAction';

const initialState = {
  mod: {},
  modules: null,
  isLoading: false
};
const moduleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MODULE_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          mod: payload.data
        }),
      });
    case CLEAN_MODULE:
      return {
        ...state, mod: {}, modules: null
      };
    default:
      return state;
  }
};
export default moduleReducer;
