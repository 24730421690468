import {
  Pagination, Select
} from 'antd';
import { PropTypes, string } from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../../../components/table';
import { usePagination } from '../../../hooks/usePagination';
import { getSensorByValues } from '../../../redux/actions/sensor';
import { parserHistoricaltList } from '../../../utils/parsers/establishment';
import { parseSelectModules } from '../../../utils/parsers/module';
import { columnsHistorical } from '../../../utils/table-parser';
import SpinnerInto from '../../../components/spinner-inner-components';

const Historical = ({ modules, status }) => {
  const { id } = useParams();
  const [filterStatus, setFilterStatus] = useState(status);
  const {
    dataSource, handlePage, page, total, isLoading, onChangeStatus
  } = usePagination(getSensorByValues, 'historical', id, filterStatus);

  const handleFilterChange = (newStatus) => {
    setFilterStatus(newStatus);
    handlePage(1);
    onChangeStatus(newStatus);
  };
  return (
    <div>
      <p className="container-page__subtitle">Historial</p>
      <div>
        <Select
          options={parseSelectModules(modules)}
          value={filterStatus}
          onChange={handleFilterChange}
          size="large"
          className="select-module"
        />
        <div className="container-page__table">
          {!dataSource
            ? <SpinnerInto />
            : (
              <Table
                columns={columnsHistorical}
                data={parserHistoricaltList(dataSource)}
                actions={false}
                isLoading={isLoading}
              />
            )}
        </div>
      </div>
      <div className="container-page__pagination">
        <Pagination
          current={page}
          onChange={handlePage}
          total={total}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};
Historical.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape()),
  status: string.isRequired,
};
Historical.defaultProps = {
  modules: []
};
export default Historical;
