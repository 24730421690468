/* eslint-disable import/no-cycle */
import { SENSOR_PARAMETERS_API_PATH } from '../../service/path';
import {
  CREATE_PARAMETER,
  DELETE_PARAMETER,
  UPDATE_PARAMETER,
} from './types';
import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const createParameter = (data, callback) => (dispatch) => dispatch({
  type: CREATE_PARAMETER,
  promise: api.post(SENSOR_PARAMETERS_API_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El parametro fue creado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteParemeterById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_PARAMETER,
  promise: api.delete(`${SENSOR_PARAMETERS_API_PATH}/${id}`),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El parametro fue eliminado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateParemeterById = (id, data, callback) => (dispatch) => dispatch({
  type: UPDATE_PARAMETER,
  promise: api.put(`${SENSOR_PARAMETERS_API_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El parametro fue editado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});
