/* eslint-disable import/no-cycle */
import { CROP_PROFILE_API_PATH, CROP_PROFILE_MODULO_API_PATH } from '../../service/path';
import {
  CLEAN_CROP_PROFILE,
  CREATE_CROP_PROFILE,
  DELETE_CROP_PROFILE,
  GET_CROP_PROFILE,
  GET_CROP_PROFILE_BY_ID,
  UPDATE_CROP_PROFILE,
  UPDATE_CROP_PROFILE_ORDER
} from './types';
import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const getAllCropProfiles = (perPage, offSet) => (dispatch) => dispatch({
  type: GET_CROP_PROFILE,
  promise: api.get(`${CROP_PROFILE_API_PATH}?perPage=${perPage}&offSet=${offSet}`),
});

export const getAllCropProfilesNotPagination = () => (dispatch) => dispatch({
  type: GET_CROP_PROFILE,
  promise: api.get(CROP_PROFILE_API_PATH),
});

export const getAllCropProfilesModulo = (id) => (dispatch) => dispatch({
  type: GET_CROP_PROFILE,
  promise: api.get(`${CROP_PROFILE_MODULO_API_PATH}/${id}`),
});
export const getCropProfileById = (id) => (dispatch) => dispatch({
  type: GET_CROP_PROFILE_BY_ID,
  promise: api.get(`${CROP_PROFILE_API_PATH}/${id}`)
});

export const getCropProfileByModuleId = (id, idModule) => (dispatch) => dispatch({
  type: GET_CROP_PROFILE_BY_ID,
  promise: api.get(`${CROP_PROFILE_API_PATH}/${id}/${idModule}`)
});

export const createCropProfile = (data, callback) => (dispatch) => dispatch({
  type: CREATE_CROP_PROFILE,
  promise: api.post(CROP_PROFILE_API_PATH, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El perfil de cultivo fue creado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const deleteCropProfileById = (id, callback) => (dispatch) => dispatch({
  type: DELETE_CROP_PROFILE,
  promise: api.delete(`${CROP_PROFILE_API_PATH}/${id}`),
  meta: {
    onSuccess: (res) => {
      if (res.data.statusCode === 200) {
        showSuccessMessage('El perfil de cultivo fue eliminado correctamente', dispatch);
      }
      callback(res.data.statusCode);
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateCropProfileById = (id, data, callback) => (dispatch) => dispatch({
  type: UPDATE_CROP_PROFILE,
  promise: api.put(`${CROP_PROFILE_API_PATH}/${id}`, data),
  meta: {
    onSuccess: () => {
      showSuccessMessage('El perfil de cultivo fue editado correctamente', dispatch);
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const updateCropProfileOrder = (data, callback) => (dispatch) => dispatch({
  type: UPDATE_CROP_PROFILE_ORDER,
  promise: api.post(`${CROP_PROFILE_API_PATH}/order`, data),
  meta: {
    onSuccess: () => {
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});

export const cleanCropProfile = () => (dispatch) => dispatch({
  type: CLEAN_CROP_PROFILE,
});
