export const selectModules = ({
  numero
}) => ({
  value: numero,
  label: `Nro módulo ${numero}`
});

export const parseSelectModules = (list) => {
  const all = {
    value: 'all',
    label: 'Todos'
  };
  const modules = list?.map((module) => selectModules(module));
  return [all, ...modules];
};
