const addCero = (date) => ((date >= 0 && date < 10) ? `0${date}` : date);

export const fechaHora = (fecha) => {
  const fechaNew = new Date(fecha);
  return `${addCero(fechaNew.getDate())}/${addCero(fechaNew.getMonth() + 1)}/${fechaNew.getFullYear()} ${addCero(fechaNew.getHours())}:${addCero(fechaNew.getMinutes())}:${addCero(fechaNew.getSeconds())}`;
};

export const parseDate = (date) => {
  const dateNow = new Date(date);
  return `${addCero(dateNow.getDate())}/${addCero(dateNow.getMonth() + 1)}/${dateNow.getFullYear()}`;
};

export const getLastWeek = () => {
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  return parseDate(lastWeek);
};

export const fechaHoraUTC = (fecha) => {
  const fechaNew = new Date(fecha);
  return `${addCero(fechaNew.getUTCDate())}/${addCero(fechaNew.getUTCMonth() + 1)}/${fechaNew.getUTCFullYear()} ${addCero(fechaNew.getUTCHours())}:${addCero(fechaNew.getUTCMinutes())}:${addCero(fechaNew.getUTCSeconds())}`;
};
