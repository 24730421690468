/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Row, Select
} from 'antd';
import Button from '../../../button';
import Input from '../../../input';
import { getValidationRules, validateFieldsMaximoMinimo } from '../../../../utils/validations';

const { Item } = Form;

const FormDetailCropProfile = ({
  onSubmit, initialValues, onChange, sensorTypes, sensors, isLoading, sensor
}) => {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({});
  const { minimoAdmisible, maximoAdmisible } = sensor;

  const onFinish = (values) => {
    onSubmit({
      ...values,
      key: initialValues.key
    });
  };
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
      form.resetFields();
    });
  };

  const handleFormChange = (changedValues, allValues) => {
    setErrors(validateFieldsMaximoMinimo(allValues, minimoAdmisible, maximoAdmisible));
    onChange(allValues);
    if (Object.keys(changedValues)[0] === 'typeSensores') {
      form.resetFields(['sensor']);
      form.setFieldsValue({ sensor: '' });
    }
  };

  const hasErrors = () => {
    const errorsFields = form.getFieldsError();
    return (Object.keys(errorsFields).some((field) => errorsFields[field])
    && Object.keys(errors).length !== 0);
  };

  useEffect(() => {
    setErrors({});
    form.setFieldsValue({
      ...initialValues
    });
  }, [initialValues]);

  useEffect(() => {
    if (sensor) {
      form.setFieldsValue({
        minimoCritico: minimoAdmisible,
        maximoCritico: maximoAdmisible
      });
    }
  }, [sensor]);

  return (
    <Form layout="vertical" form={form} initialValues={initialValues} onValuesChange={handleFormChange}>
      <Row gutter={24}>
        <Col xs={24} lg={12} span={24}>
          <Item
            name="typeSensores"
            rules={getValidationRules('un tipo de sensor', 'select')}
            label="Tipo de sensores"
          >
            <Select
              options={sensorTypes}
            />
          </Item>
        </Col>
        <Col xs={24} lg={12} span={24}>
          <Item
            name="sensor"
            label="Sensores"
            rules={getValidationRules('un sensor', 'select')}
          >
            <Select
              options={sensors}
              disabled={isLoading}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={12} span={24}>
          <Item
            name="minimo"
            label="Minimo"
            rules={getValidationRules('el minimo')}
            validateStatus={errors.minimo ? 'error' : ''}
            help={errors.minimo}
          >
            <Input type="number" max={form.getFieldValue('maximo')} />
          </Item>
        </Col>
        <Col xs={24} lg={12} span={24}>
          <Item
            name="minimoCritico"
            label="Minimo crítico"
            rules={getValidationRules('el minimo critico')}
            validateStatus={errors.minimoCritico ? 'error' : ''}
            help={errors.minimoCritico}
          >
            <Input
              type="number"
              max={form.getFieldValue('minimo') && parseInt(form.getFieldValue('minimo'), 10) - 1}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={12} span={24}>
          <Item
            name="maximo"
            label="Máximo"
            rules={getValidationRules('el maximo')}
            validateStatus={errors.maximo ? 'error' : ''}
            help={errors.maximo}
          >
            <Input
              type="number"
              min={parseInt(form.getFieldValue('minimo'), 10) + 1}
              max={parseInt(form.getFieldValue('maximoCritico'), 10) - 1}
            />
          </Item>
        </Col>
        <Col xs={24} lg={12} span={24}>
          <Item
            name="maximoCritico"
            label="Máximo critico"
            rules={getValidationRules('el maximo critico')}
            validateStatus={errors.maximoCritico ? 'error' : ''}
            help={errors.maximoCritico}
          >
            <Input
              type="number"
              min={parseInt(form.getFieldValue('maximo'), 10) + 1}
            />
          </Item>
        </Col>
      </Row>
      <div style={{ marginTop: '2rem' }}>
        <Item>
          <Button
            disabled={hasErrors()}
            type="primary"
            onClick={handleSubmit}
            text="Aceptar"
          />
        </Item>

      </div>
    </Form>
  );
};

FormDetailCropProfile.propTypes = {
  initialValues: PropTypes.shape(),
  sensorTypes: PropTypes.arrayOf(PropTypes.shape()),
  sensors: PropTypes.arrayOf(PropTypes.shape()),
  sensor: PropTypes.shape(),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool
};
FormDetailCropProfile.defaultProps = {
  initialValues: {},
  sensor: {},
  sensorTypes: [],
  sensors: [],
  onSubmit: () => {},
  onChange: () => {},
  isLoading: false
};

export default FormDetailCropProfile;
