import { handleAction } from '../../utils/handleAction';
import {
  CLEAN_BINNACLE, GET_BINNACLE_BY_ID, GET_BINNACLE, GET_BINNACLE_TYPES
} from '../actions/types';

const initialState = {
  binnacle: null,
  binnacles: [],
  isLoading: false
};
const binnacleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BINNACLE_TYPES:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          binnacles: payload.data
        }),
      });
    case GET_BINNACLE_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          binnacle: payload.data
        }),
      });

    case GET_BINNACLE:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          binnacles: payload.data
        }),
      });
    case CLEAN_BINNACLE:
      return { ...state, binnacle: {}, binnacles: null };
    default:
      return state;
  }
};
export default binnacleReducer;
