/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app';
import {
  getMessaging, getToken, onMessage, isSupported
} from 'firebase/messaging';
import { api } from './service/api';
import { TOKEN_FIREBASE_PATH } from './service/path';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCCg5HJVu3gi57-cI8zkdIfrVvnbsCGmj0',
//   authDomain: 'invernadero-1814b.firebaseapp.com',
//   projectId: 'invernadero-1814b',
//   storageBucket: 'invernadero-1814b.appspot.com',
//   messagingSenderId: '302831387268',
//   appId: '1:302831387268:web:45082180536b4a8fb678d1',
//   measurementId: 'G-5BN7TB3T3H'
// };

const firebaseConfig = {
  apiKey: 'AIzaSyBEU6-A9KxcwiTlZJi_sQXdww0iDTgvHGY',
  authDomain: 'invernaderos-4f5be.firebaseapp.com',
  projectId: 'invernaderos-4f5be',
  storageBucket: 'invernaderos-4f5be.appspot.com',
  messagingSenderId: '269020855008',
  appId: '1:269020855008:web:cda88c27905d3e04e46e85',
  measurementId: 'G-19367J9MCR'
};

export const firebaseApp = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase is not supported in this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getOrRegisterServiceWorker = () => {
  if (
    'serviceWorker' in navigator
    && typeof window.navigator.serviceWorker !== 'undefined'
  ) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          }
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

// eslint-disable-next-line consistent-return
export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    const rol = localStorage.getItem('rol');
    if (messagingResolve && rol !== 'superadmin') {
      return getToken(messagingResolve, {
        vapidKey: 'BE49sIkvSH3vzDelSoe2NES_-OIMzjykDGcyz90heaWSdH7ooW5HWinpMSqyjskKKYkkPHvTQ202JEmaelaVQ2Y',
      }).then((resp) => {
        api.post(TOKEN_FIREBASE_PATH, { token: resp });
        localStorage.setItem('tokenFirebase', resp);
      });
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});
