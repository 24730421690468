import { api } from '../../service/api';
import { BINNACLES_PATH, BINNACLES_TYPE_PATH } from '../../service/path';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';
import { CREATE_BINNACLE, GET_BINNACLE_BY_ID, GET_BINNACLE_TYPES } from './types';

export const getBinnacleById = (id) => (dispatch) => dispatch({
  type: GET_BINNACLE_BY_ID,
  promise: api.get(`${BINNACLES_PATH}/${id}`)
});

export const getAllBinnacleTypes = () => (dispatch) => dispatch({
  type: GET_BINNACLE_TYPES,
  promise: api.get(BINNACLES_TYPE_PATH),
});

export const createBinnacle = (data, callback) => (dispatch) => dispatch({
  type: CREATE_BINNACLE,
  promise: api.post(BINNACLES_PATH, data),
  meta: {
    onSuccess: () => {
      if (data.gramos) {
        showSuccessMessage('La bitácora fue cerrada correctamente', dispatch);
      } else {
        showSuccessMessage('La bitácora fue creada correctamente', dispatch);
      }
      callback();
    },
    onFailure: (res) => {
      showErrorMessage(res.response.data.message, dispatch);
    }
  }
});
