export const parseCroppoint = ({
  _id,
  nombre,
  color,
  order
}) => ({
  key: _id,
  nombre,
  color,
  ordenDePerfil: order && order
});

export const parserCroppointList = (list) => list?.map((camp) => parseCroppoint(camp));
