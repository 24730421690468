import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Space } from 'antd';
import { useDispatch } from 'react-redux';
import { columsCroppoint } from '../../../utils/table-parser';
import Button from '../../../components/button';
import { parserCroppointList } from '../../../utils/parsers/croppoint';
import Table from '../../../components/table';
import Input from '../../../components/input';
import { updateCropProfileOrder } from '../../../redux/actions/crop-profile';

const Croppoint = ({ cropprofiles, getData }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [orderInput, setOrderInput] = useState([]);
  const [orderList, setOrderList] = useState([]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };
  const handleSaveClick = () => {
    dispatch(updateCropProfileOrder(orderInput, getData));
    setEditing(false);
  };

  const handleInputChange = (order, key) => {
    const existingIndex = orderInput.findIndex((item) => item.key === key);
    if (existingIndex !== -1) {
      const updatedOrderInput = orderInput.map((item) => {
        if (item.key === key) {
          return { key, order };
        }
        return item;
      });
      setOrderInput(updatedOrderInput);
    } else {
      const orderCropp = {
        key,
        order
      };
      const updatedOrderInput = [...orderInput, orderCropp];
      setOrderInput(updatedOrderInput);
    }
  };

  useEffect(() => {
    const datalist = [];
    cropprofiles?.forEach(({ _id, order }) => {
      const objectData = {
        key: _id,
        order
      };
      datalist.push(objectData);
    });
    setOrderList(datalist);
  }, [editing]);

  const getOrder = (key) => {
    const orderListFind = orderList.find((item) => item.key === key);
    const orderInputFind = orderInput.find((item) => item.key === key);
    return orderInputFind ? orderInputFind?.order : orderListFind?.order;
  };

  const actionColumn = (cropprofile) => (
    <Space>
      {editing
        ? (
          <Input
            type="number"
            value={getOrder(cropprofile.key)}
            onChange={(e) => handleInputChange(e.target.value, cropprofile.key)}
          />
        )
        : <p>{cropprofile?.ordenDePerfil}</p>}
    </Space>
  );

  // ORDENAMIENTO ALFABETICAMENTE

  const croppointData = parserCroppointList(cropprofiles) || [];

  const sortedData = [...croppointData];

  sortedData.sort((a, b) => {
    const nameA = a.nombre.toLowerCase();
    const nameB = b.nombre.toLowerCase();

    if (a.ordenDePerfil == null && b.ordenDePerfil == null) {
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    } if (a.ordenDePerfil == null) {
      return 1;
    } if (b.ordenDePerfil == null) {
      return -1;
    } if (a.ordenDePerfil === b.ordenDePerfil) {
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }
    return a.ordenDePerfil - b.ordenDePerfil;
  });

  return (
    <div>
      <p className="container-page__subtitle">Perfil de cultivo</p>
      <div>
        <div className="button-order-perfil">
          {
            editing
              ? (
                <>
                  <Button
                    text="Guardar"
                    onClick={handleSaveClick}
                  />
                  <Button
                    text="Cancelar"
                    onClick={handleCancelClick}
                  />
                </>
              )
              : (
                <Button
                  text="Editar orden de perfil"
                  onClick={handleEditClick}
                />
              )
          }
        </div>
        <div className="container-page__table">
          <Table
            columns={columsCroppoint}
            data={sortedData}
            actions={false}
            order
            actionsColumn={actionColumn}
          />
        </div>
      </div>
    </div>
  );
};

Croppoint.propTypes = {
  cropprofiles: (PropTypes.shape()).isRequired,
  getData: PropTypes.func.isRequired,
};

export default Croppoint;
