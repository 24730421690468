const toCamelCase = (str) => str.split(' ').map((word, index) => {
  let parsedWord = word;
  parsedWord = parsedWord.replace('á', 'a');
  parsedWord = parsedWord.replace('Á', 'A');
  parsedWord = parsedWord.replace('é', 'e');
  parsedWord = parsedWord.replace('í', 'i');
  parsedWord = parsedWord.replace('ó', 'o');
  parsedWord = parsedWord.replace('ú', 'u');
  if (index === 0) {
    return parsedWord.toLowerCase();
  }
  return parsedWord.charAt(0).toUpperCase() + parsedWord.slice(1).toLowerCase();
}).join('');
const parserColumns = (columns) => columns.map((item) => ({
  title: item,
  dataIndex: toCamelCase(item),
  key: toCamelCase(item),
}));

export const columnsUser = parserColumns(['Nombre', 'Apellido', 'Email', 'Rol']);
export const columnsCropProfile = parserColumns(['Color', 'Nombre', 'Super perfil']);
export const columnsModules = parserColumns(['Número', 'Perfil de cultivo']);
export const columnsSensor = parserColumns(['Nombre', 'Unidad', 'Tipo sensor', 'Máximo admisible', 'Minimo admisible']);
export const columnsParameters = parserColumns(['Minimo Critico', 'Minimo', 'Máximo', 'Máximo Critico', 'Sensor']);
export const columnsHistorical = parserColumns(['Nro módulo', 'Parámetro', 'Estado', 'Valor', 'Fecha']);
export const columnsCampaign = parserColumns(['Fecha de inicio', 'Fecha de cierre', 'Módulo', 'Perfil de cultivo', 'Gramos']);
export const columnsCampaignDetail = parserColumns(['Fecha', 'Acontecimiento', 'Comentarios']);
export const columsSuperPerfil = parserColumns(['Sensor', 'Mínimo', 'Máximo']);
export const columsVarieties = parserColumns(['Nombre', 'Banco de semillas']);
export const columsCroppoint = parserColumns(['Color', 'Nombre']);
