import { handle } from 'redux-pack';
import {
  GET_SENSOR_VALUES,
} from '../actions/types';

const initialState = {
  historicals: null,
  isLoading: false,
};
const historicalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SENSOR_VALUES:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isLoading: true }),
        finish: (prevState) => ({ ...prevState, isLoading: false }),
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          historicals: payload.data
        }),
      });

    default:
      return state;
  }
};
export default historicalReducer;
