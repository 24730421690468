/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';
import {
  Progress, Button
} from 'antd';
import CarouselCard from '../../carrusel/card-carousel';
import SpinnerInto from '../../spinner-inner-components';

const InfoParameter = ({
  onHiddenParameters,
  onViewInfoSensor,
  isLoading,
  cropprofile,
}) => {
  const getColor = (estado) => {
    if (estado === 'Critico bajo' || estado === 'Critico alto') return '#ff1c18';
    if (estado === 'No óptimo bajo' || estado === 'No óptimo alto') return '#ffbf00';
    return '#7ab929';
  };
  const getValue = (valorActual, minimoAdmisible, maximoAdmisible) => {
    const result = ((valorActual - minimoAdmisible) / (maximoAdmisible - minimoAdmisible)) * 100;
    if (result < 1 && result >= 0) {
      return 1;
    }
    return result;
  };

  return (
    <div>
      {
         isLoading ? (
           <SpinnerInto />
         )
           : (
             <>
               <div className="card-info-home__button-nav">
                 <Button icon={<LeftOutlined />} color="transparent-all" onClick={onHiddenParameters} />
               </div>
               <div className="card-info-home__parametersHeigth">
                 <p className="card-info-home__subtitle">{`${cropprofile?.nombre === undefined ? '' : cropprofile?.nombre}`}</p>
                 <div>
                   <div>
                     {cropprofile?.parametros
         && (
         <div className="card-info-home__sensor-mobile">
           <CarouselCard styles="slick-grid" countToShow={cropprofile?.parametros?.length}>
             {cropprofile?.parametros?.map(
               ({
                 _id,
                 sensor: {
                   nombre,
                   valorActual,
                   estado,
                   unidad,
                   tipoSensor,
                   minimoAdmisible,
                   maximoAdmisible,
                 },
               }) => (
                 <div key={_id} className="card-info-home__module">
                   <p>{tipoSensor.nombre === 'Virtual' ? nombre : tipoSensor.nombre}</p>
                   <Progress
                     type="dashboard"
                     percent={getValue(valorActual, minimoAdmisible, maximoAdmisible)}
                     strokeColor={getColor(estado)}
                     strokeWidth={10}
                     format={() => (
                       <div className="progress-format-style">
                         {valorActual ? (
                           <span className="ant-progress-text">{`${valorActual} ${unidad.nombre}`}</span>
                         ) : (
                           <span className="ant-progress-text-notValue">Sin valor</span>
                         )}
                       </div>
                     )}
                     trailColor="#e9e9e9"
                     size={90}
                     onClick={() => onViewInfoSensor(_id)}
                   />
                 </div>
               )
             )}
           </CarouselCard>
         </div>
         )}
                     {cropprofile === null
        && <p className="messege-not-content">El módulo no tiene perfil de cultivo asociado.</p>}
                     {cropprofile !== undefined && cropprofile?.parametros?.length === 0
        && <p className="messege-not-content">El perfil de cultivo no tiene parametros asociados.</p>}
                   </div>
                 </div>
               </div>
             </>

           )
      }
    </div>
  );
};

InfoParameter.propTypes = {
  onHiddenParameters: PropTypes.func.isRequired,
  onViewInfoSensor: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  cropprofile: PropTypes.shape(),
};

InfoParameter.defaultProps = {
  isLoading: false,
  cropprofile: {},
};

export default InfoParameter;
