/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Row
} from 'antd';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { getValidationRules, getValidationUmbrales } from '../../../utils/validations';

const { Item } = Form;

const FormDetailEstablishment = ({
  onSubmit, initialValues, isLoading
}) => {
  const [form] = Form.useForm();

  const [errors, setErrors] = useState({});

  const onFinish = (values) => {
    onSubmit(values);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  const handleChange = (changedValues, allValues) => {
    setErrors(getValidationUmbrales(allValues));
  };

  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, [initialValues]);

  return (
    <Form form={form} layout="vertical" onValuesChange={handleChange} initialValues={initialValues} className="form-container">
      <Item
        className="form-container__item"
        name="nombre"
        label="Nombre"
        rules={getValidationRules('el nombre')}
      >
        <Input />
      </Item>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Item
            label="Umbral 1"
            name="umbral1"
            rules={getValidationRules('el valor de umbral 1')}
            validateStatus={errors.umbral1 ? 'error' : ''}
            help={errors.umbral1}
          >
            <Input type="number" />
          </Item>
        </Col>
        <Col xs={24} sm={12}>
          <Item
            label="Umbral 2"
            name="umbral2"
            rules={getValidationRules('el valor de umbral 2')}
            validateStatus={errors.umbral2 ? 'error' : ''}
            help={errors.umbral2}
          >
            <Input type="number" />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Item label="Latitud" name="latitud">
            <Input type="number" min={null} />
          </Item>
        </Col>
        <Col xs={24} sm={12}>
          <Item label="Longitud" name="longitud">
            <Input type="number" min={null} />
          </Item>
        </Col>
        <Col>
          <Item label="Tiempo de ultima actualizacion" name="tiempo_actualizacion">
            <Input type="number" min={null} />
          </Item>
        </Col>
      </Row>

      <Item>
        <Button
          type="primary"
          onClick={handleSubmit}
          text="Guardar cambios"
          loading={isLoading}
        />
      </Item>
    </Form>
  );
};
FormDetailEstablishment.propTypes = {
  initialValues: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func
};
FormDetailEstablishment.defaultProps = {
  onSubmit: () => {},
  initialValues: {}
};

export default FormDetailEstablishment;
