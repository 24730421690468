/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { getValidationRules } from '../../../utils/validations';

const { Item } = Form;

const FormEstablishment = ({ onSubmit, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  return (
    <Form form={form} key="form-establishment" layout="vertical" initialValues={initialValues} className="form-container">
      <Item
        className="form-container__item"
        name="nombre"
        label="Nombre"
        rules={getValidationRules('el nombre')}
      >
        <Input />
      </Item>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Item label="Latitud" name="latitud">
            <Input type="number" min={null} />
          </Item>
        </Col>
        <Col xs={24} sm={12}>
          <Item label="Longitud" name="longitud">
            <Input type="number" min={null} />
          </Item>
        </Col>
        <Col xs={24} sm={12}>
          <Item label="Tiempo de ultima actualizacion" name="tiempo_actualizacion">
            <Input type="number" min={null} />
          </Item>
        </Col>
      </Row>

      <Item>
        <Button type="primary" onClick={handleSubmit} text="Aceptar" />
      </Item>
    </Form>
  );
};
FormEstablishment.propTypes = {
  initialValues: PropTypes.shape(),
  onSubmit: PropTypes.func
};
FormEstablishment.defaultProps = {
  onSubmit: () => {},
  initialValues: {}
};

export default FormEstablishment;
