/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Col, Input, Modal, Row
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { columsVarieties } from '../../../utils/table-parser';
import Table from '../../../components/table';
import Button from '../../../components/button';
import { parseVarietiesList } from '../../../utils/parsers/varieties';
import CampaingForm from '../../establishment-detail/campaign/form';
import { updateCampaignById } from '../../../redux/actions/campaign';

const Varieties = (campaign) => {
  const [showModalModuleEdit, setShowModalModuleEdit] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const openModalEdit = () => {
    setShowModalModuleEdit(true);
  };

  const closeModalEdit = () => {
    setShowModalModuleEdit(false);
  };

  const handleModalEdit = () => {
    openModalEdit();
  };

  const onEditCampaing = (form) => {
    dispatch(updateCampaignById(id, form));
    closeModalEdit();
  };

  return (
    <div>
      <p className="container-page__subtitle">Variedades</p>
      <div>
        <div className="container-varieties">
          <div className="container-varieties__rows">
            <Row gutter={[32, 32]} align="middle">
              <Col xs={24} sm={12} md={12}>
                <p>Plantas por m2 (unidades)</p>
                <Input disabled value={campaign?.campaign?.cantPlantas} />
              </Col>
              <Col xs={24} sm={12} md={12}>
                <p>Volumen por macetas (litros)</p>
                <Input disabled value={campaign?.campaign?.volMacetas} />
              </Col>
            </Row>
          </div>
          <div className="container-varieties__button">
            <Button
              text="Editar"
              onClick={handleModalEdit}
            />
          </div>
        </div>
        <div className="container-page__table">
          <Table
            columns={columsVarieties}
            actions={false}
            data={parseVarietiesList(campaign?.campaign?.variables)}
          />
        </div>
      </div>
      <Modal
        title="Propiedades de la campaña"
        open={showModalModuleEdit}
        onOk={closeModalEdit}
        onCancel={closeModalEdit}
        footer={null}
      >
        <CampaingForm
          onSubmit={onEditCampaing}
          initialValues={campaign?.campaign}
        />
      </Modal>
    </div>
  );
};

export default Varieties;
