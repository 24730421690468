/* eslint-disable no-undef */
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cleanEstablishment, createEstablishment, getAllEstablishments } from '../../redux/actions/establishment';
import Button from '../../components/button';
import Card from '../../components/card';
import Carousel from '../../components/carrusel';
import Spinner from '../../components/spinner';
import Title from '../../components/title';
import withAuth from '../../hoc';
import { ESTABLISHMENTS_PATH } from '../../routes/path';
import { parserEstablishmentList } from '../../utils/parsers/establishment';
import FormEstablishment from './form';
import Icon from '../../components/icon';

const initialValues = {
  nombre: ''
};

const Establishment = () => {
  const dispatch = useDispatch();
  const { establishments, isLoading } = useSelector((state) => state.establishmentReducer);
  const rol = localStorage.getItem('rol');
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
    if (showModal) dispatch(getAllEstablishments());
  };

  const handleCreate = (data) => {
    dispatch(createEstablishment(data, handleShowModal));
  };

  useEffect(() => {
    dispatch(getAllEstablishments());
    return () => {
      dispatch(cleanEstablishment());
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    setIsMobile(window.innerWidth <= 768);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Establecimiento" />
        {rol !== 'user'
          && (
          <Button
            text="Agregar establecimiento +"
            color="transparent"
            onClick={handleShowModal}
          />
          )}
      </div>
      {
        isLoading
          ? <Spinner />
          : (
            <div>
              { establishments
              && (
              <div>
                {establishments?.length !== 0
                  ? (
                    <div>
                      {isMobile ? (
                        <div className="container-page__card-center">
                          {parserEstablishmentList(establishments).map(({ key, nombre }) => (
                            <Card key={key}>
                              <Link className="card-info__establishment" to={`${ESTABLISHMENTS_PATH}/${key}`}>
                                <Icon name="Plantacion" />
                                <p>{nombre}</p>
                              </Link>
                            </Card>
                          ))}
                        </div>
                      ) : (
                        <div className="container-page__carousel">
                          <Carousel countToShow={establishments.length}>
                            { parserEstablishmentList(establishments).map(({ key, nombre }) => (
                              <Card key={key}>
                                <Link className="card-info__establishment" to={`${ESTABLISHMENTS_PATH}/${key}`}>
                                  <Icon name="Plantacion" />
                                  <p>{nombre}</p>
                                </Link>
                              </Card>
                            ))}
                          </Carousel>
                        </div>
                      )}
                    </div>

                  )
                  : (
                    <p className="container-page__subtitle">No hay establecimientos</p>
                  )}
              </div>
              )}
            </div>
          )
      }
      <Modal
        title="Crear nuevo establecimiento"
        open={showModal}
        onOk={handleShowModal}
        onCancel={handleShowModal}
        footer={null}
      >
        <FormEstablishment initialValues={initialValues} onSubmit={handleCreate} />
      </Modal>
    </div>
  );
};

export default withAuth(Establishment);
