/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Space } from 'antd';
import { Chart } from 'react-google-charts';
import { fechaHora } from '../../../utils/parsers/date';

const ChartReport = ({ data }) => {
  const { perfilcultivo: { parametros } } = data;
  const size = () => {
    const { width } = window.screen;
    if (width > 600) return { width: 600, height: 450 };
    if (width < 600 && width > 450) return { width: 500, height: 300 };
    if (width < 450) return { width: 350, height: 350 };
    return 0;
  };
  const [sizeGraph] = useState(size());
  const getSeries = (sensor) => {
    const valoresSensores = sensor.valores.map(({ valor, createdAt }) => [
      fechaHora(createdAt),
      valor
    ]);
    return [['x', sensor.nombre], ...valoresSensores];
  };
  const options = (sensor) => ({
    title: sensor.tipoSensor.nombre,
    width: sizeGraph.width,
    height: sizeGraph.height,
    curveType: 'function',
    legend: { position: 'bottom' },
    vAxis: {
      title: 'Valores',
      titleTextStyle: {
        fontSize: 25,
        italic: false,
      },
      chartArea: {
        height: '80%', // Ajusta este valor para cambiar la proporción del espacio para los ejes x
      },
    },
  });
  return (
    <>
      <Space
        direction="vertical"
        size="small"
      >
        <h2 className="chart__title">{`Modulo ${data.numero}`}</h2>
        <p className="chart__id">{`ID: ${data._id}`}</p>
      </Space>
      <Card className="chart">
        {parametros.length !== 0
          ? (
            <div className="chart__graph">
              {parametros.map(({ sensor }, _id) => (
                <div key={_id}>
                  {
              sensor.valores.length !== 0 ? (
                <Chart
                  chartType="LineChart"
                  options={options(sensor)}
                  data={getSeries(sensor)}
                  width={sizeGraph.width}
                  height={sizeGraph.height}
                />
              )
                : <p className="messege-not-content">No hay valores almacenados</p>
            }
                </div>
              ))}
            </div>
          )
          : <p className="messege-not-content">No hay registros almacenados</p>}
      </Card>
    </>
  );
};
ChartReport.propTypes = {
  data: PropTypes.shape()
};
ChartReport.defaultProps = {
  data: {}
};
export default ChartReport;
