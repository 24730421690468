/* eslint-disable no-underscore-dangle */
export const parserSensor = ({
  _id, nombre, tipoSensor, unidad, descripcion, maximoAdmisible, minimoAdmisible, establecimientos
}) => ({
  key: _id,
  nombre,
  tipoSensor: tipoSensor?.nombre,
  tipoSensorId: tipoSensor?._id,
  unidad: unidad?.nombre,
  unidadId: unidad?._id,
  descripcion,
  maximoAdmisible,
  minimoAdmisible,
  establecimientos
});

export const parserSensorList = (list) => list?.map((user) => parserSensor(user));
