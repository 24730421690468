import { LeftOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import React from 'react';
import { fechaHora } from '../../../utils/parsers/date';
import Button from '../../button';

const InfoSensor = ({ data, onHiddenSensor }) => {
  const {
    cropprofile,
    sensor: {
      descripcion, unidad, tipoSensor, valorActual, fecha, estado
    },
    maximo, maximoCritico, minimo, minimoCritico
  } = data;
  return (
    <div>
      <div className="card-info-home__button-nav">
        <Button icon={<LeftOutlined />} color="transparent" onClick={onHiddenSensor} />
      </div>
      <div className="card-info-home__sensorHeigth">
        <p className="card-info-home__subtitle">{cropprofile}</p>
        <div className="card-info-home__info">
          <p>
            <span>Parámetro: </span>
            {tipoSensor.nombre}
          </p>
          <p>
            <span>Descripcion: </span>
            {descripcion}
          </p>
          <p>
            <span>Descripción de medida: </span>
            {unidad.nombre}
          </p>
          <p>
            <span>Valor crítico maximo: </span>
            {maximoCritico}
          </p>
          <p>
            <span>Valor óptimo maximo: </span>
            {maximo}
          </p>
          <p>
            <span>Valor óptimo mínimo: </span>
            {minimo}
          </p>
          <p>
            <span>Valor crítico mínimo: </span>
            {minimoCritico}
          </p>
          {valorActual && (
          <p>
            <span>Valor actual: </span>
            {`${valorActual} ${unidad.nombre}`}
          </p>
          )}
          {estado && (
          <p>
            <span>Estado: </span>
            {estado}
          </p>
          )}
          {fecha && (
          <p>
            <span>Fecha: </span>
            {fechaHora(fecha)}
          </p>
          )}
        </div>
      </div>
    </div>
  );
};
InfoSensor.propTypes = {
  data: PropTypes.shape().isRequired,
  onHiddenSensor: PropTypes.func.isRequired
};

export default InfoSensor;
