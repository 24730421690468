/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import { Form, Select, Col, } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button from '../../button';
// import { getValidationRules } from '../../../../utils/validations';
import Input from '../../input';
import { parserSelect } from '../../../utils/parsers/establishment';

const { Item } = Form;

const FormAcontecimientos = ({
  onSubmit, initialValues, cropprofile, binnacles
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    const newAcontecimiento = {
      ...values,
      campaña: cropprofile?.campaña?._id,
      perfilcultivo: cropprofile?._id,
      fecha: new Date()
    };
    onSubmit(newAcontecimiento);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
    });
  }, [initialValues]);

  return (
    <Form form={form} layout="vertical">
      <Col offset={6}>
        <Item label="Opciones" name="tipo" colon={false}>
          <Select options={parserSelect(binnacles)} />
        </Item>
        <Item label="Comentarios" name="comentarios" colon={false}>
          <Input />
        </Item>
      </Col>
      <div className="container-page__center-buttons">
        <Button type="primary" onClick={handleSubmit} text="Guardar" />
      </div>
    </Form>

  );
};
FormAcontecimientos.propTypes = {
  initialValues: PropTypes.shape(),
  cropprofile: PropTypes.shape(),
  binnacles: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func,
};
FormAcontecimientos.defaultProps = {
  onSubmit: () => {},
  cropprofile: {},
  binnacles: [],
  initialValues: {}
};

export default FormAcontecimientos;
