import React from 'react';
import PropTypes from 'prop-types';
import { columnsCampaignDetail } from '../../../utils/table-parser';
import Table from '../../../components/table';
import { parseBinnacleList } from '../../../utils/parsers/binnacles';

const Binnacle = ({ bitacoras }) => (
  <div>
    <p className="container-page__subtitle">Bitácora</p>
    <div>
      <div className="container-page__table">
        <Table
          columns={columnsCampaignDetail}
          actions={false}
          data={parseBinnacleList(bitacoras)}
        />
      </div>
    </div>

  </div>
);

Binnacle.propTypes = {
  bitacoras: PropTypes.arrayOf(PropTypes.shape()),
};
Binnacle.defaultProps = {
  bitacoras: [],
};

export default Binnacle;
