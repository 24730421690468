/* eslint-disable no-undef */
/* eslint-disable no-promise-executor-return */
import {
  Menu, Popconfirm, Popover
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../button';
import Icon from '../../../icon';
import { menu, menuRolUser } from '../../../../utils/menu-nav';
import { ROOT_PAGE_PATH } from '../../../../routes/path';
// import LogoCrop from '../../../../assets/img/iconoHemp.png';
import LogoHemp from '../../../../assets/img/logoHemp.png';

const HeaderDesktop = ({
  nombre, onLogout, notificationPermission,
  notificacion, onNotifications, openModal
}) => {
  const { pathname } = useLocation();
  const token = localStorage.getItem('accessToken');
  const rol = localStorage.getItem('rol');

  return (
    <>
      <Header>
        {token && rol && (
        <div className="menu-desktop__container">
          <Link to={ROOT_PAGE_PATH}>
            <div className="menu-desktop__container-logo">
              {/* <img className="menu-desktop__containe-logo__log" src={LogoCrop} alt="logo" />
              <img src={Hemp} alt="logo" className="menu-desktop__logo" /> */}
              <img src={LogoHemp} alt="logo" className="menu-desktop__logo" />
            </div>
          </Link>
          <div className="menu-desktop__nav">
            <Menu
              selectedKeys={pathname}
              mode="horizontal"
              items={rol !== 'user' ? menu : menuRolUser}
            />
            {notificationPermission === 'granted'
              ? (
                <Popconfirm
                  placement="bottomLeft"
                  title={notificacion ? '¿Desea desactivar las notificaciones?' : '¿Desea activar las notificaciones?'}
                  onConfirm={onNotifications}
                  width="10rem"
                  okText="Si"
                  cancelText="No"
                  style={{ width: '10rem' }}
                >
                  {
                   rol !== 'superadmin' && (
                   <Button
                     icon={notificacion ? <Icon name="Bell" size={25} /> : <Icon name="DisableWhite" size={25} />}
                     color="transparent"
                   />
                   )
                    }
                </Popconfirm>
              )
              : (
                <Popover
                  title="Debe activar los permisos en la aplicacion."
                  placement="bottomLeft"
                >
                  {
                 rol !== 'superadmin' && (
                 <Button
                   icon={<Icon name="DisableBlack" size={25} />}
                   color="transparent"
                   onClick={openModal}
                 />
                 )
                  }
                </Popover>
              )}
            <div className="menu-desktop__user">
              <Popconfirm
                placement="bottomLeft"
                title={`¿Desea cerrar la sesión ${nombre}?`}
                onConfirm={onLogout}
                width="10rem"
                okText="Si"
                cancelText="No"
                style={{ width: '10rem' }}
              >
                <Button
                  icon={<Icon name="User" size={75} />}
                  color="transparent"
                />
              </Popconfirm>

            </div>

          </div>
        </div>

        )}
      </Header>
      <div className="menu-desktop-barBottom" />
    </>
  );
};

HeaderDesktop.propTypes = {
  nombre: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  notificationPermission: PropTypes.string.isRequired,
  notificacion: PropTypes.bool.isRequired,
  onNotifications: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};
export default HeaderDesktop;
