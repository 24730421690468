/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import { Card } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const CardComponent = ({ children, login, ...restProps }) => (
  <Card {...restProps} className={login ? 'card-login' : 'card-info'}>
    {children}
  </Card>
);
CardComponent.propTypes = {
  children: PropTypes.node.isRequired,
  login: PropTypes.bool,
};
CardComponent.defaultProps = {
  login: false,
};
export default CardComponent;
