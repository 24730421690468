/* eslint-disable no-underscore-dangle */
export const parserProfile = ({
  _id, nombre, color, superPerfil,
}) => ({
  key: _id,
  _id,
  nombre,
  color,
  superPerfil: superPerfil === 'Si' ? 'Si' : 'No'
});

export const parserProfileNewTable = ({
  key, sensor, maximo, maximoCritico, minimo, minimoCritico, typeSensores
}) => ({
  key,
  sensor: sensor?.nombre,
  sensorId: sensor?._id,
  maximo,
  maximoCritico,
  minimo,
  minimoCritico,
  typeSensores
});

export const parserProfileNew = ({
  sensor, maximo, maximoCritico, minimo, minimoCritico, color, nombre
}) => ({
  color,
  nombre,
  sensor: sensor?._id,
  maximo,
  maximoCritico,
  minimo,
  minimoCritico,

});

export const parserParameter = ({
  _id, maximo, maximoCritico, minimo, minimoCritico, sensor
}) => ({
  key: _id,
  sensor: sensor?.nombre,
  sensorId: sensor?._id,
  maximo,
  maximoCritico,
  minimo,
  minimoCritico,
  typeSensores: sensor?.tipoSensor?._id
});

export const parserSuperPerfil = ({
  _id, maximo, minimo, sensor
}) => ({
  key: _id,
  maximo,
  minimo,
  sensor,
});

export const parserCropProfileList = (list) => list?.map((profile) => parserProfile(profile));

export const parserParameterList = (list) => list?.map((parameter) => parserParameter(parameter));

export const parserSuperPerfilList = (list) => list?.map(
  (superPerfil) => parserSuperPerfil(superPerfil)
);

export const parserCropProfileNewList = (list) => list?.map(
  (perfil) => parserProfileNewTable(perfil)
);

export const parserSelectProfile = (list) => list?.map((perfil) => ({
  value: perfil?._id,
  label: perfil?.nombre
}));
