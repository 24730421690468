import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Space } from 'antd';
import Table from '../../table';
import { columsSuperPerfil } from '../../../utils/table-parser';
import { parserSuperPerfilList } from '../../../utils/parsers/crop-profile';

const TableSuperPerfil = ({ data }) => (
  <div>
    {data.map(({
      _id, alerta, recomendacion, configuraciones
    }) => (
      <Space.Compact block key={_id} direction="vertical">
        <p className="messege-superPerfil">{`Alerta: ${alerta}`}</p>
        <p className="messege-superPerfil">{`Recomendacion: ${recomendacion}`}</p>
        <Table
          columns={columsSuperPerfil}
          actions={false}
          data={parserSuperPerfilList(configuraciones)}
        />
        <Divider />
      </Space.Compact>

    ))}
  </div>
);

TableSuperPerfil.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};
TableSuperPerfil.defaultProps = {
  data: [],
};
export default TableSuperPerfil;
