/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Col, Form, message, Row, Select
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Button from '../../../components/button';
import { getValidationMaximoMinimo, getValidationRules } from '../../../utils/validations';
import Input from '../../../components/input';
import { parserSelect } from '../../../utils/parsers/establishment';

const { Item } = Form;

const FormSensor = ({
  onSubmit, initialValues, units, types, establishments,
  selectDefault, disabled = false,
}) => {
  const [form] = Form.useForm();
  const { unidadId, tipoSensorId } = initialValues;
  const [errors, setErrors] = useState({});
  const rol = localStorage.getItem('rol');

  const onFinish = (data) => {
    onSubmit(data);
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  const handleChange = (changedValues, allValues) => {
    setErrors(getValidationMaximoMinimo(allValues));
  };
  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      unidad: unidadId,
      tipoSensor: tipoSensorId,
      establecimientos: selectDefault,
    });
  }, [initialValues, selectDefault, establishments]);

  return (
    <Form form={form} layout="vertical" onValuesChange={handleChange} initialValues={initialValues}>
      {initialValues.key && (
      <Row gutter={24}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item name="key" label="Id sensor">
            <div className="form-container__copy">
              <Input
                disabled={disabled}
                defaultValue={initialValues && initialValues.key}
              />

              <CopyToClipboard
                text={initialValues.key}
                onCopy={() => message.success('Copiado')}
              >
                <CopyOutlined className="form-container__icon-copy" />
              </CopyToClipboard>
            </div>
          </Item>
        </Col>
      </Row>
      )}
      <Row gutter={24}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item
            name="nombre"
            label="Nombre"
            rules={getValidationRules('el nombre')}
          >
            <Input
              disabled={disabled}
            />
          </Item>
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item name="descripcion" label="Descripción" rules={getValidationRules('la descripción')}>
            <Input
              disabled={disabled}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item name="unidad" label="Unidad" rules={getValidationRules('una unidad', 'select')}>
            <Select
              disabled={disabled}
              options={parserSelect(units)}
            />
          </Item>
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item name="tipoSensor" label="Tipo de sensor" rules={getValidationRules('la unidad', 'select')}>
            <Select
              disabled={disabled}
              options={parserSelect(types)}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item
            name="minimoAdmisible"
            label="Min admisible"
            rules={getValidationRules('el minimo admisible')}
            validateStatus={errors.minimoAdmisible ? 'error' : ''}
            help={errors.minimoAdmisible}
          >
            <Input
              disabled={disabled}
              type="number"
            />
          </Item>
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <Item
            name="maximoAdmisible"
            label="Máx admisible"
            validateStatus={errors.maximoAdmisible ? 'error' : ''}
            help={errors.maximoAdmisible}
            rules={getValidationRules('máximo admisible')}
          >
            <Input
              disabled={disabled}
              type="number"
            />
          </Item>
        </Col>
      </Row>
      <Item required={rol !== 'superadmin'} name="establecimientos" label="Establecimientos">
        <Select
          disabled={disabled}
          mode="tags"
          style={{
            width: '100%',
          }}
          tokenSeparators={[',']}
          options={establishments}
        />
      </Item>
      <Item>
        { !disabled && (
          <Button type="primary" onClick={handleSubmit} text="Aceptar" />
        )}
      </Item>
    </Form>
  );
};
FormSensor.propTypes = {
  initialValues: PropTypes.shape(),
  units: PropTypes.arrayOf(PropTypes.shape()),
  types: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func,
  establishments: PropTypes.arrayOf(PropTypes.shape()),
  selectDefault: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool.isRequired
};
FormSensor.defaultProps = {
  onSubmit: () => {},
  units: [],
  types: [],
  initialValues: {},
  establishments: [],
  selectDefault: []

};

export default FormSensor;
