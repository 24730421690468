import React, { useEffect } from 'react';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Title from '../../components/title';
import Binnacle from './binnacle';
import InfoDetail from './info-detail';
import { getCampaignById } from '../../redux/actions/campaign';
import Spinner from '../../components/spinner';
import Varieties from './varieties';

const CampaignDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    campaign, isLoading
  } = useSelector((state) => state.campaignReducer);

  useEffect(() => {
    dispatch(getCampaignById(id));
  }, []);

  return (
    <div className="container-page">
      <div className="container-page__header">
        <Title text="Detalle de la campaña" />
      </div>
      <Divider />
      {isLoading ? <Spinner />
        : (
          <div>
            <InfoDetail campaign={campaign} />
            <Divider />
            <Varieties campaign={campaign} />
            <Divider />
            <Binnacle bitacoras={campaign.bitacoras} />
          </div>
        )}
    </div>
  );
};

export default CampaignDetail;
