import {
  CLEAN_USER,
  GET_USER,
  GET_USER_BY_ID,
  GET_USER_ESTABLISHMENT,
  GET_USER_PROFILE,
} from '../actions/types';
import { handleAction } from '../../utils/handleAction';

const initialState = {
  user: {},
  users: [],
  userProfile: {},
  isLoading: false
};
const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          users: payload.data
        }),
      });
    case GET_USER_ESTABLISHMENT:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          users: payload.data
        }),
      });
    case GET_USER_BY_ID:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          user: payload.data
        }),
      });
    case GET_USER_PROFILE:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          userProfile: payload.data
        }),
      });
    case CLEAN_USER:
      return { ...state, user: {}, users: [] };
    default:
      return state;
  }
};
export default userReducer;
